import React, { useState, useEffect } from "react";
import ChangeCompanyModal from "../ChangeCompanyModal";
import { getCompanies } from "../../api/company";
import { getDefaultCompanyUuid } from "../../lib/persistence";
import { useGlobalContext } from "../../GlobalContext";
import { isLoggedIn } from "../../lib/auth";
import { Link } from "react-router-dom";

const SelectedCompany = () => {
  const { state, setState } = useGlobalContext();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [otherCompanies, setOtherCompanies] = useState([]);
  const [changingCompanies, setChangingCompanies] = useState(false);

  useEffect(() => {
    const loadCompanies = async () => {
      if (!isLoggedIn()) {
        return;
      }
      try {
        const companiesList = await getCompanies();
        setCompanies(companiesList);
        if (companiesList.length < 1) {
          return;
        }

        const defaultCompanyUuid = getDefaultCompanyUuid();
        let selectedIdx = companiesList.findIndex(
          (company) => company.uuid === defaultCompanyUuid,
        );
        if (selectedIdx === -1) {
          selectedIdx = 0;
        }
        setSelectedCompany(companiesList[selectedIdx]);
        setState((prevState) => ({
          ...prevState,
          selectedCompany: companiesList[selectedIdx].uuid,
        }));
        setOtherCompanies(
          companiesList.filter(
            (company) =>
              companiesList[selectedIdx] &&
              company.uuid !== companiesList[selectedIdx].uuid,
          ),
        );
      } catch (error) {
        console.error("Failed to load companies", error);
      }
    };
    loadCompanies();
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    setChangingCompanies(true);
  };

  if (!isLoggedIn()) {
    return;
  }

  if (companies.length < 1) {
    return (
      <div className="flex flex-col items-center justify-center">
        <span className="text-lg text-secondaryColor">
          You are not a part of any company.
        </span>
        <Link
          to={"/create-company"}
          className="text-base text-blue-300 hover:text-blue-500"
        >
          Create one?
        </Link>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <span className="text-lg text-secondaryColor">
          You are currently viewing <b>{selectedCompany?.name}</b>
        </span>
        {selectedCompany?.active === false ? (
          <span className="text-base text-red-500">
            This company is deactivated. Please contact a system administrator
            to activate it.
          </span>
        ) : null}
        {otherCompanies && otherCompanies.length > 0 ? (
          <button
            onClick={handleClick}
            className="text-base text-blue-300 hover:text-blue-500"
          >
            Change Company
          </button>
        ) : (
          <Link
            to={"/create-company"}
            className="text-base text-blue-300 hover:text-blue-500"
          >
            Create new Company
          </Link>
        )}
      </div>
      <ChangeCompanyModal
        isOpen={changingCompanies}
        onCompanySelected={(uuid) => {
          const company = companies.find((c) => c.uuid === uuid);
          if (!company) {
            return;
          }
          setState({
            ...state,
            selectedCompany: company.uuid,
          });
          setSelectedCompany(company);
          setOtherCompanies(companies.filter((c) => c.uuid !== uuid));
          setChangingCompanies(false);
        }}
        onCancel={() => setChangingCompanies(false)}
        companies={companies.filter(
          (company) => company.uuid !== selectedCompany?.uuid,
        )}
      />
    </>
  );
};

export default SelectedCompany;
