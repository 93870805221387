import React from "react";

import { MdOutlineDeleteForever } from "react-icons/md";
import PropTypes from "prop-types";
import WasteIcon from "../WasteIcon";
import {
  frequencyLookup,
  wasteTypeNameLookup,
  weeklyFrequencyLookup,
} from "../../lib/lookups";

const SelectedWasteService = ({ selectedService, setServices, index }) => {
  const handleDelete = () => {
    setServices((prevServices) => {
      const updatedServices = prevServices.toSpliced(index, 1);
      return updatedServices;
    });
  };

  return (
    <div className="flex size-fit flex-col items-center rounded-md border border-transparent bg-slate-200 p-1 text-center shadow-sm lg:p-3 xl:p-5">
      <div className="mb-1 size-8 md:size-14 lg:mb-2 lg:size-20 xl:size-24">
        <WasteIcon wasteType={selectedService.waste_type} />
      </div>
      <p className="mb-1 block text-[0.5rem] font-bold text-black md:text-[0.65rem] lg:mb-2 lg:text-xs xl:text-sm">
        {wasteTypeNameLookup[selectedService.waste_type]?.name}
      </p>
      <p className="block text-[0.5rem] font-bold text-black md:text-[0.65rem] lg:mb-1 lg:text-xs xl:text-sm">
        {selectedService.container_type}
      </p>
      <p className="block text-[0.5rem] font-bold text-black md:text-[0.65rem] lg:mb-1 lg:text-xs xl:text-sm">
        Quantity: {selectedService.quantity}
      </p>
      <p className="block text-[0.5rem] font-bold text-black md:text-[0.65rem] lg:mb-1 lg:text-xs xl:text-sm">
        Frequency:{" "}
        {+selectedService.frequency >= 4.33
          ? weeklyFrequencyLookup[selectedService.frequency]
          : frequencyLookup[selectedService.frequency]}
      </p>

      <button onClick={handleDelete}>
        <MdOutlineDeleteForever className="mb-1 size-3 text-errorColor md:size-4 lg:mb-2 lg:size-6 xl:size-8" />
      </button>
    </div>
  );
};
SelectedWasteService.propTypes = {
  selectedService: PropTypes.object.isRequired,
  setServices: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  frequencyLookup: PropTypes.object.isRequired,
  wasteTypeNameLookup: PropTypes.object.isRequired,
};

export default SelectedWasteService;
