import React from "react";
import PropTypes from "prop-types";
import WasteIcon from "../WasteIcon";
import {
  frequencyLookup,
  wasteTypeNameLookup,
  weeklyFrequencyLookup,
} from "../../lib/lookups";

const QuoteSelectionWasteService = ({ selectedService }) => {
  return (
    <div className="flex size-fit flex-col items-center rounded-md border border-transparent bg-slate-200 p-1.5 text-center shadow-sm lg:p-3 xl:p-5">
      <div className="size-5 md:size-7 lg:mb-1 lg:size-10 xl:size-16">
        <WasteIcon wasteType={selectedService.waste_type} />
      </div>
      <p className="block text-[5px] font-bold text-black md:text-[8.5px] lg:mb-1 lg:text-[10px] xl:text-xs">
        {wasteTypeNameLookup[selectedService.waste_type]}
      </p>
      <p className="block text-[5px] font-bold text-black md:text-[8.5px] lg:mb-1 lg:text-[10px] xl:text-xs">
        {selectedService.container_type}
      </p>
      <p className="block text-[5px] font-bold text-black md:text-[8.5px] lg:mb-1 lg:text-[10px] xl:text-xs">
        Quantity: {selectedService.quantity}
      </p>
      <p className="block text-[5px] font-bold text-black md:text-[8.5px] lg:text-[10px] xl:text-xs">
        {+selectedService.frequency >= 4.33
          ? weeklyFrequencyLookup[selectedService.frequency]
          : frequencyLookup[selectedService.frequency]}
      </p>
    </div>
  );
};
QuoteSelectionWasteService.propTypes = {
  selectedService: PropTypes.object.isRequired,
};

export default QuoteSelectionWasteService;
