import React from "react";
import PageWrapper from "../../components/PageWrapper";
import CompanyDetails from "../../components/CompanyDetails";

const Company = () => {
  return (
    <PageWrapper>
      <div className="size-full p-6 xl:p-8 2xl:p-10">
        <CompanyDetails />
      </div>
    </PageWrapper>
  );
};

export default Company;
