import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { singleLineAddress } from "../../lib/formatters";
import { toast } from "react-toastify";
import { getCompany, updateCompany } from "../../api/company";

const ManageCompany = ({ companyUuid, onClose }) => {
  const [activeIsWorking, setActiveIsWorking] = useState(false);
  const [company, setCompany] = useState(null);

  const fetchCompany = async () => {
    try {
      const company = await getCompany(companyUuid);
      setCompany(company);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch company");
      onClose();
    }
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  const handleActiveChange = async () => {
    setActiveIsWorking(true);
    const desiredState = !company.active;
    try {
      await updateCompany(company.uuid, { active: desiredState });
      toast.success(`Company ${desiredState ? "activated" : "deactivated"}`);
      await fetchCompany();
    } catch (error) {
      console.error("Failed to update company", error);
    }
    setActiveIsWorking(false);
  };

  if (!company) {
    return null;
  }

  return (
    <div className="w-[50vw] p-4">
      <div className="flex justify-end">
        <button className="ml-auto" onClick={onClose}>
          <MdClose className="text-xl" />
        </button>
      </div>

      <h3 className="mb-6 ml-2 text-2xl font-bold">{company.name}</h3>

      <div>
        <div className="relative mb-6">
          <label className="inline-flex cursor-pointer items-center">
            <label className="mr-2 bg-white px-1 text-base text-gray-500 duration-100 ease-linear 2xl:text-xl">
              Active
            </label>
            <input
              type="checkbox"
              className="peer sr-only"
              checked={company.active}
              onChange={handleActiveChange}
              disabled={activeIsWorking}
            />
            <div className="peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
          </label>
        </div>
        <div className="relative mb-6">
          <input
            type="text"
            className={`peer h-12 w-full appearance-none rounded border-2 px-3 py-2 text-base leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none 2xl:h-16 2xl:text-lg`}
            disabled
            value={company.website}
          />
          <label className="absolute left-0 top-2.5 ml-2 -translate-y-6 bg-white px-1 text-base text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-6 peer-focus:px-1 2xl:top-5 2xl:-translate-y-8 2xl:text-xl 2xl:peer-focus:-translate-y-8">
            Website
          </label>
        </div>
        <div className="relative mb-6">
          <input
            type="text"
            className={`peer h-12 w-full appearance-none rounded border-2 px-3 py-2 text-base leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none 2xl:h-16 2xl:text-lg`}
            disabled
            value={company.types?.join(", ")}
          />
          <label className="absolute left-0 top-2.5 ml-2 -translate-y-6 bg-white px-1 text-base text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-6 peer-focus:px-1 2xl:top-5 2xl:-translate-y-8 2xl:text-xl 2xl:peer-focus:-translate-y-8">
            Types
          </label>
        </div>
        <div className="relative mb-6">
          <input
            type="text"
            className={`peer h-12 w-full appearance-none rounded border-2 px-3 py-2 text-base leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none 2xl:h-16 2xl:text-lg`}
            disabled
            value={company.contact_email}
          />
          <label className="absolute left-0 top-2.5 ml-2 -translate-y-6 bg-white px-1 text-base text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-6 peer-focus:px-1 2xl:top-5 2xl:-translate-y-8 2xl:text-xl 2xl:peer-focus:-translate-y-8">
            Contact Email
          </label>
        </div>
        <div className="relative mb-6">
          <input
            type="text"
            className={`peer h-12 w-full appearance-none rounded border-2 px-3 py-2 text-base leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none 2xl:h-16 2xl:text-lg`}
            disabled
            value={company.contact_phone_number}
          />
          <label className="absolute left-0 top-2.5 ml-2 -translate-y-6 bg-white px-1 text-base text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-6 peer-focus:px-1 2xl:top-5 2xl:-translate-y-8 2xl:text-xl 2xl:peer-focus:-translate-y-8">
            Contact Phone Number
          </label>
        </div>
        <div className="relative mb-6">
          <input
            type="text"
            className={`peer h-12 w-full appearance-none rounded border-2 px-3 py-2 text-base leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none 2xl:h-16 2xl:text-lg`}
            disabled
            value={singleLineAddress(company)}
          />
          <label className="absolute left-0 top-2.5 ml-2 -translate-y-6 bg-white px-1 text-base text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-6 peer-focus:px-1 2xl:top-5 2xl:-translate-y-8 2xl:text-xl 2xl:peer-focus:-translate-y-8">
            Address
          </label>
        </div>
      </div>
    </div>
  );
};

export default ManageCompany;
