import React, { useState, useEffect } from "react";
import {
  fetchWhitelabelDetails,
  createWhitelabelDomain,
  deleteWhitelabelDomain,
  verifyWhitelabelDomain,
} from "../../api/quote";
import { useGlobalContext } from "../../GlobalContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../Modal";

const WhitelabelDomain = () => {
  const { state } = useGlobalContext();
  const [whitelabelDetailsLoading, setWhitelabelDetailsLoading] =
    useState(true);
  const [whitelabelDetails, setWhitelabelDetails] = useState(null);
  const [isEditingWhitelabelDetails, setIsEditingWhitelabelDetails] =
    useState(false);

  const [formData, setFormData] = useState({
    domain: "",
  });
  const [formValidation, setFormValidation] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    formValidation === false && value && setFormValidation(true);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.domain) {
      setFormValidation(false);
      return;
    }
    const submit = async () => {
      try {
        await createWhitelabelDomain(state.selectedCompany, formData.domain);
        setIsEditingWhitelabelDetails(false);
        await loadWhitelabelDetails();
        toast.success("Whitelabel Domain Created");
      } catch (err) {
        console.error(err);
        toast.error(err.message);
      }
    };

    submit();
  };

  const handleVerify = async () => {
    try {
      await verifyWhitelabelDomain(state.selectedCompany);
      setWhitelabelDetails((prev) => ({
        ...prev,
        verified: true,
      }));
    } catch (err) {
      console.error(err);
      toast.error(err.message);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteWhitelabelDomain(state.selectedCompany);
      setWhitelabelDetails(null);
      toast.success("Whitelabel Domain Deleted");
    } catch (err) {
      console.error(err);
    }
  };

  const loadWhitelabelDetails = async () => {
    try {
      const whitelabelDetails = await fetchWhitelabelDetails(
        state.selectedCompany,
      );
      setWhitelabelDetails(whitelabelDetails);
    } catch (error) {
      console.error("Failed to load company", error);
      toast.error("Failed to load company");
    } finally {
      setWhitelabelDetailsLoading(false);
    }
  };

  useEffect(() => {
    loadWhitelabelDetails();
  }, [state.selectedCompany]);

  return (
    <>
      <div className="h-fit min-w-[300px] max-w-[600px] rounded bg-slate-200 p-6 shadow-lg">
        <h2 className="mb-2 text-xl font-bold text-black">
          Domain Configuration
        </h2>
        <p className="mb-3 block text-base text-black">
          Setup a domain of your control to be served by Switch Waste's tailored
          quote builder. Once setup, you can configure the look and feel of the
          quotation interface for your customers.
        </p>

        {whitelabelDetailsLoading ? (
          <h2 className="text-center text-lg">Loading...</h2>
        ) : whitelabelDetails ? (
          <div className="w-full">
            <p className="mb-2 block text-base text-black">
              <span className="font-bold">Domain: </span>
              {whitelabelDetails.domain}
            </p>
            <p className="mb-2 block text-base font-bold text-black">
              Status:&nbsp;
              {whitelabelDetails.verified ? (
                <span className="bg-successColor p-2 text-white">
                  {" "}
                  Verified
                </span>
              ) : (
                <span className="bg-errorColor p-2 text-white">
                  {" "}
                  Unverified
                </span>
              )}
            </p>
            {!whitelabelDetails.verified && (
              <>
                <p className="mb-2 block text-base text-black">
                  <span className="font-bold">Verification Code: </span>
                  {whitelabelDetails.verification_code}
                </p>
                <p className="mb-2 block text-center text-base text-black">
                  To verify, set the verification code as a TXT record on the
                  specified domain.
                </p>
              </>
            )}
            <div className="flex justify-center gap-6">
              <button
                className="rounded-md border border-transparent bg-errorColor px-4 py-2 text-center text-base text-white shadow-sm transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white"
                onClick={handleDelete}
              >
                Delete this domain
              </button>
              {!whitelabelDetails.verified && (
                <button
                  onClick={handleVerify}
                  disabled={whitelabelDetails.verified}
                  className="rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-sm text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none xl:px-6 xl:text-base 2xl:px-8 2xl:text-lg"
                >
                  Verify Now
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="flex w-full flex-col items-center">
            <p className="mb-3 block text-center text-lg text-errorColor xl:mb-4 xl:text-xl 2xl:mb-5 2xl:text-2xl">
              No Whitelabel Configuration found.
            </p>
            <button
              onClick={() => setIsEditingWhitelabelDetails(true)}
              className="rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-sm text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none xl:px-6 xl:text-base 2xl:px-8 2xl:text-lg"
            >
              Configure Whitelabel Domain
            </button>
          </div>
        )}
      </div>
      <Modal isOpen={isEditingWhitelabelDetails}>
        <div className="flex w-[50vw] flex-col items-center p-6 xl:p-8 2xl:p-10">
          <h3 className="mb-2 block text-xl font-bold text-black xl:mb-3 xl:text-2xl 2xl:mb-4 2xl:text-3xl">
            Whitelabel Configuration
          </h3>
          <p className="mb-4 block text-center text-sm text-black xl:mb-5 xl:text-base 2xl:mb-8 2xl:text-lg">
            Configure our Quote Builder to serve one of your domains with a
            tailored quote building process. This should be a domain under your
            control. In order to verify this, we will ask you to create a TXT
            record with a unique value on your domain. Once verified, you can
            configure your domain to serve the quote builder.
          </p>
          <form
            onSubmit={handleSubmit}
            className="flex w-full flex-col items-center"
          >
            <div className="relative mb-5 w-3/4 xl:mb-6 2xl:mb-8">
              <input
                id="domain"
                name="domain"
                value={formData.domain}
                onChange={handleChange}
                placeholder="quotes.yourdomain.com"
                className={`peer appearance-none border-2 shadow ${formValidation ? "border-successColor ring-successColor" : formValidation === false && "border-errorColor ring-errorColor"} h-10 w-full rounded px-3 py-2 text-base leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring 2xl:h-12 2xl:px-5 2xl:py-4 2xl:text-lg`}
              />
              <label
                className="absolute left-0 top-1.5 ml-2 -translate-y-4 bg-white px-1 text-base text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 2xl:top-2 2xl:ml-4 2xl:-translate-y-6 2xl:text-lg 2xl:peer-focus:ml-4 2xl:peer-focus:-translate-y-6"
                htmlFor="domain"
              >
                Domain
              </label>
              <p
                className={`mt-1 italic text-errorColor ${formValidation !== false && "invisible"}`}
              >
                Please provide a domain.
              </p>
            </div>
            <div className="flex justify-center gap-6 xl:gap-8 2xl:gap-10">
              <button
                type="button"
                className="rounded-md border border-transparent bg-secondaryColor px-4 py-2 text-center text-base text-primaryColor shadow-sm transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white xl:text-lg 2xl:text-xl"
                onClick={() => setIsEditingWhitelabelDetails(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-base text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none xl:text-lg 2xl:text-xl"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

WhitelabelDomain.propTypes = {};

export default WhitelabelDomain;
