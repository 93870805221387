export const relativeTimeFormatter = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();
  const diff = now - date;

  if (diff < 1000) {
    return "just now";
  } else if (diff < 60 * 1000) {
    return `${Math.floor(diff / 1000)} seconds ago`;
  } else if (diff < 60 * 60 * 1000) {
    return `${Math.floor(diff / (60 * 1000))} minutes ago`;
  } else if (diff < 24 * 60 * 60 * 1000) {
    return `${Math.floor(diff / (60 * 60 * 1000))} hours ago`;
  } else if (diff < 7 * 24 * 60 * 60 * 1000) {
    return `${Math.floor(diff / (24 * 60 * 60 * 1000))} days ago`;
  } else if (diff < 30 * 24 * 60 * 60 * 1000) {
    return `${Math.floor(diff / (7 * 24 * 60 * 60 * 1000))} weeks ago`;
  } else if (diff < 365 * 24 * 60 * 60 * 1000) {
    return `${Math.floor(diff / (30 * 24 * 60 * 60 * 1000))} months ago`;
  } else {
    return `${Math.floor(diff / (365 * 24 * 60 * 60 * 1000))} years ago`;
  }
};

export const weightFormat = (value) => (value ? `${value}kg` : "none");

export const currencyFormat = (value) =>
  new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(
    value,
  );

export const singleLineAddress = (obj) =>
  `${obj.address_line_1}, ${obj.address_line_2 + ", " || ""} ${obj.town}, ${obj.county}, ${obj.postcode}`;

export const formatCompanyRole = (role) => {
  switch (role) {
    case "admin":
      return "Admin";
    case "writer":
      return "Writer";
    case "reader":
      return "Reader";
    default:
      return "Unknown";
  }
};

export const formatSystemRole = (role) => {
  switch (role) {
    case "admin":
      return "Admin";
    case "user":
      return "User";
    default:
      return "Unknown";
  }
};
