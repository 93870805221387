import React from "react";
import PropTypes from "prop-types";
import "./index.css";
import { NavLink } from "react-router-dom";

const SideNav = ({ links }) => {
  const categories = Array.from(new Set(links.map((link) => link.category)));

  return (
    <nav className="h-[calc(100vh-112px)] w-48 overflow-y-auto bg-secondaryColor px-2 py-4">
      {categories.map((category) => (
        <div key={category} className="mb-4">
          <h3 className="text-lg font-bold text-gray-700">{category}</h3>
          <ul>
            {links
              .filter((link) => link.category === category)
              .map((link) => (
                <li key={link.id} className="border-b-2 border-gray-300 py-3">
                  <NavLink
                    to={link.url}
                    className={({ isActive }) =>
                      isActive
                        ? "text-500 text-base"
                        : "text-base text-gray-500"
                    }
                  >
                    {({ isActive }) =>
                      isActive ? `> ${link.name}` : link.name
                    }
                  </NavLink>
                </li>
              ))}
          </ul>
        </div>
      ))}
    </nav>
  );
};

SideNav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SideNav;
