import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import ProgressBar from "../ProgressBar";

const PostcodeCollectionInternal = () => {
  const navigate = useNavigate();
  const context = useOutletContext();
  const customerPostcode = context[0];
  const setCustomerPostcode = context[1];
  const pages = context[12];
  const [formDataPostcode, setFormDataPostcode] = useState(
    customerPostcode || "",
  );
  const [formValidation, setFormValidation] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidPostcode = validatePostcode();

    if (!isValidPostcode) {
      return;
    }
    setCustomerPostcode(formDataPostcode);
    navigate("/internal-quote/waste-services");
  };

  const validatePostcode = () => {
    const postcodeRegex =
      /^([a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?)\s?[0-9][a-zA-Z]{2}?/;
    if (postcodeRegex.test(formDataPostcode)) {
      setFormValidation(true);
      return true;
    } else {
      setFormValidation(false);
      return false;
    }
  };

  const handlePostcodeChange = (e) => {
    const postcodeRegex =
      /^([a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?)\s?[0-9][a-zA-Z]{2}?/;

    formValidation === false &&
      postcodeRegex.test(e.target.value) &&
      setFormValidation(true);
    setFormDataPostcode(e.target.value.toUpperCase());
  };

  return (
    <>
      <ProgressBar
        setPage={(page) => navigate(`/internal-quote/${page}`)}
        currPageIndex={0}
        pages={pages}
      />
      <div className="flex size-full flex-col items-center justify-center px-4 pb-16 pt-12 xl:pb-20 xl:pt-16">
        <h1 className="mb-4 block text-center text-lg font-bold text-black md:text-xl lg:mb-10 lg:text-2xl xl:mb-20 xl:text-3xl">
          Hi, what is your postcode?
        </h1>
        <form
          onSubmit={handleSubmit}
          noValidate
          className="flex w-3/4 flex-col items-center lg:w-2/3"
        >
          <div className="relative w-3/4 md:mb-4 lg:mb-8 xl:mb-16">
            <input
              type="text"
              id="postcode"
              className={`peer appearance-none border-2 shadow ${formValidation ? "border-successColor ring-successColor" : formValidation === false && "border-errorColor ring-errorColor"} h-10 w-full rounded px-2 py-1 text-base leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring md:h-12 lg:h-16 lg:px-3 lg:py-2 lg:text-lg`}
              name="postcode"
              value={formDataPostcode || ""}
              onChange={handlePostcodeChange}
              onBlur={validatePostcode}
              required
              placeholder="Postcode"
            />
            <label
              htmlFor="postcode"
              className="absolute left-0 top-2 ml-2 -translate-y-5 bg-white px-1 text-base text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-5 peer-focus:px-1 md:top-2.5 md:-translate-y-6 md:peer-focus:-translate-y-6 lg:top-4 lg:-translate-y-8 lg:text-xl lg:peer-focus:-translate-y-8"
            >
              Postcode
            </label>
            <p
              className={`my-1 text-xs italic text-errorColor md:text-sm lg:text-base ${formValidation !== false && "invisible"}`}
            >
              Please provide a valid postcode.
            </p>
          </div>
          <button
            className="rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-base text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none lg:h-14 lg:px-8 lg:text-lg xl:text-2xl"
            type="submit"
          >
            Next
          </button>
        </form>
      </div>
    </>
  );
};

export default PostcodeCollectionInternal;
