import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { relativeTimeFormatter } from "../../lib/formatters";
import { singleLineAddress } from "../../lib/formatters";
import { getCompany } from "../../api/company";
import { useGlobalContext } from "../../GlobalContext";
import { toast } from "react-toastify";
import { isLoggedIn } from "../../lib/auth";

const TextDetail = ({ label, value }) => (
  <div className="relative mb-6">
    <input
      type="text"
      className={`peer h-12 w-full appearance-none rounded border-2 px-3 py-2 text-base leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none`}
      disabled
      value={value}
    />
    <label className="absolute left-0 top-2.5 ml-2 -translate-y-6 bg-white px-1 text-base text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-6 peer-focus:px-1">
      {label}
    </label>
  </div>
);

const CompanyDetails = () => {
  const [company, setCompany] = useState(null);
  const { state } = useGlobalContext();

  useEffect(() => {
    const loadCompany = async () => {
      try {
        if (!state.selectedCompany) {
          return;
        }
        const company = await getCompany(state.selectedCompany);
        setCompany(company);
      } catch (error) {
        toast.error("Failed to load company");
        console.error(error);
      }
    };

    if (isLoggedIn()) {
      loadCompany();
    }
  }, [state.selectedCompany]);

  if (!company) {
    return;
  }

  return (
    <div className="size-full">
      <h1 className="mb-8 text-2xl font-bold text-black">Company Details</h1>
      <div className="mt-4 max-w-lg">
        <TextDetail label="Name" value={company.name} />
        <TextDetail
          label="Types"
          value={company.types
            .map(
              (type) =>
                type.substring(0, 1).toUpperCase() +
                type.substring(1, type.length),
            )
            .join(", ")}
        />
        <TextDetail label="Website" value={company.website || "None"} />
        <TextDetail
          label="Contact Email Address"
          value={company.contact_email}
        />
        <TextDetail
          label="Contact Phone Number"
          value={company.contact_phone_number}
        />
        <TextDetail label="Address" value={singleLineAddress(company)} />
        <p className="text-sm italic text-gray-500">
          Created {relativeTimeFormatter(company.created_at)}, last updated{" "}
          {relativeTimeFormatter(company.updated_at)}
        </p>
      </div>
    </div>
  );
};

PropTypes.CompanyDetails = {
  company: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    types: PropTypes.arrayOf(PropTypes.string),
    website: PropTypes.string,
    contact_email: PropTypes.string.isRequired,
    contact_phone_number: PropTypes.string.isRequired,
    address_line_1: PropTypes.string.isRequired,
    address_line_2: PropTypes.string,
    town: PropTypes.string.isRequired,
    county: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
};

export default CompanyDetails;
