import React, { useEffect } from "react";
import { currencyFormat } from "../../lib/formatters";
import { downloadPDF } from "../../lib/pdf";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  frequencyLookup,
  wasteTypeNameLookup,
  weeklyFrequencyLookup,
} from "../../lib/lookups";

const QuoteAccepted = () => {
  const context = useOutletContext();
  const navigate = useNavigate();
  const customerDetails = context[1];
  const services = context[2];
  const desiredQuote = context[9];
  const PDFBytes = context[13];

  useEffect(() => {
    if (customerDetails?.postcode === "") {
      navigate("/quote/postcode");
      return;
    } else if (services.length === 0) {
      navigate("/quote/waste-services");
      return;
    } else if (customerDetails?.email === "") {
      navigate("/quote/email");
      return;
    } else if (!desiredQuote) {
      navigate("/quote/quote-selection");
      return;
    } else if (customerDetails?.name === "") {
      navigate("/quote/customer-details");
      return;
    }
  }, []);

  const th = "px-1.5 py-1 lg:px-3 lg:py-2";
  const td =
    "px-1.5 py-1 text-[0.5rem] leading-3 text-gray-900 md:text-xs lg:px-3 lg:py-2 lg:text-sm";

  return (
    <div className="w-full p-4 xl:p-6 2xl:p-8">
      <div className="border-b border-slate-600 pb-3 lg:border-b-2 lg:pb-5">
        <p className="pb-3 text-base text-black md:text-lg lg:pb-5 lg:text-lg xl:pb-8 xl:text-xl">
          Thank you for accepting the quote. An email with the details has been
          sent to <span className="italic">{customerDetails?.email}</span>. The
          supplier will get in touch with you about the sale.
        </p>
        {PDFBytes !== null && (
          <button
            className="rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-xs text-secondaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white md:text-sm lg:text-sm"
            onClick={() => downloadPDF(PDFBytes)}
          >
            Download PDF
          </button>
        )}
      </div>
      <h2 className="mb-1 mt-3 text-base font-bold text-black md:text-lg lg:mb-2 lg:mt-5 lg:text-xl xl:mb-3 xl:mt-8 xl:text-2xl 2xl:mb-4 2xl:mt-12 2xl:text-3xl">
        Quote Details
      </h2>
      <p className="mb-1 text-xs text-black md:text-sm lg:mb-2 lg:text-base xl:mb-3 xl:text-lg 2xl:mb-4 2xl:text-xl">
        <strong>Supplier:</strong> {desiredQuote?.supplier_uuid}
      </p>
      <p className="border-b border-slate-600 pb-3 text-xs text-black md:text-sm lg:border-b-2 lg:pb-5 lg:text-base xl:pb-8 xl:text-lg 2xl:pb-12 2xl:text-xl">
        <strong>Total Monthly Price:</strong>{" "}
        {currencyFormat(desiredQuote?.price_gbp)}
      </p>
      <h2 className="mb-1 mt-3 text-base font-bold text-black md:text-sm lg:mb-2 lg:mt-5 lg:text-xl xl:mb-3 xl:mt-8 xl:text-2xl 2xl:mb-4 2xl:mt-12 2xl:text-3xl">
        Service Breakdown
      </h2>
      <div className="relative w-full overflow-x-auto">
        <table className="w-full table-auto text-left text-[0.5rem] text-gray-500 md:text-xs lg:text-sm">
          <thead className="bg-gray-100 text-[0.5rem] uppercase text-gray-700 md:text-[0.6rem] lg:text-xs">
            <tr>
              <th className={th}>Type</th>
              <th className={th}>Container</th>
              <th className={th}>Price</th>
              <th className={th}>Quantity</th>
              <th className={th}>Frequency</th>
              <th className={th}>Monthly Price</th>
              <th className={th}>Excess Weight Charge</th>
              <th className={th}>Weight Restriction</th>
            </tr>
          </thead>
          <tbody>
            {desiredQuote?.price_breakdown?.map((breakdown) => (
              <tr
                key={`${breakdown.type}.${breakdown.container}`}
                className="border-b bg-white"
              >
                <td className={td}>{wasteTypeNameLookup[breakdown.type]}</td>
                <td className={td}>{breakdown.container}</td>
                <td className={td}>{currencyFormat(breakdown.price_gbp)}</td>
                <td className={td}>{breakdown.quantity}</td>
                <td className={td}>
                  {+breakdown.frequency >= 4.33
                    ? weeklyFrequencyLookup[breakdown.frequency]
                    : frequencyLookup[breakdown.frequency]}
                </td>
                <td className={td}>
                  {currencyFormat(breakdown.total_price_gbp)}
                </td>
                <td className={td}>
                  {breakdown.excess_weight_charge_gbp
                    ? currencyFormat(breakdown.excess_weight_charge_gbp)
                    : "None"}
                </td>
                <td className={td}>
                  {breakdown.weight_restriction_kilos ?? "None"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default QuoteAccepted;
