import React, { useState } from "react";
import PropTypes from "prop-types";
import { createWasteContainerInfo } from "../../api/quote";
import { useGlobalContext } from "../../GlobalContext";
import { toast } from "react-toastify";
import { containerTypes, wasteTypes } from "../../lib/lookups";

const AddWasteContainerInfo = ({ closeModalCb }) => {
  const { state } = useGlobalContext();
  const [formData, setFormData] = useState({
    container_type: "240LTR",
    waste_type: "CRD",
    excess_weight_charge_gbp: 0,
  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "number" ? parseFloat(value) : value,
      };

      return updatedData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const create = async () => {
      try {
        await createWasteContainerInfo(state.selectedCompany, {
          ...formData,
        });
        toast.success("Waste container created");
        closeModalCb();
      } catch (error) {
        if (error.status === 400) {
          toast.error((await error.json()).error);
          return;
        }
        if (error.status === 409) {
          toast.error("Waste Container Info already exists");
          return;
        }
        toast.error("Create failed");
        console.error(error);
      }
    };

    create();
  };

  return (
    <div className="flex flex-col items-center justify-center p-8 xl:p-10 2xl:p-12">
      <h2 className="mb-4 block text-center text-base font-bold text-black xl:mb-6 xl:text-lg">
        Add Waste Container Info
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-1 w-full">
          <label
            htmlFor="container_type"
            className="mb-1 block text-base text-gray-800"
          >
            Container Type
          </label>
          <select
            id="container_type"
            name="container_type"
            className="h-8 w-full rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring"
            value={formData.container_type}
            onChange={handleChange}
            required
          >
            {containerTypes?.map((container, index) => (
              <option key={index} value={container.value}>
                {container.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-1 w-full">
          <label
            htmlFor="waste_type"
            className="mb-1 block text-base text-gray-800"
          >
            Waste Type
          </label>
          <select
            id="waste_type"
            name="waste_type"
            className="h-8 w-full rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring"
            value={formData.waste_type}
            onChange={handleChange}
            required
          >
            {wasteTypes?.map((wasteType, index) => (
              <option key={index} value={wasteType.value}>
                {wasteType.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-1 w-full">
          <label
            htmlFor="excess_weight_charge_gbp"
            className="mb-1 block text-base text-gray-800"
          >
            Excess Weight Charge (GBP)
          </label>
          <input
            type="number"
            id="excess_weight_charge_gbp"
            name="excess_weight_charge_gbp"
            className="h-8 w-full appearance-none rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring"
            value={formData.excess_weight_charge_gbp}
            onChange={handleChange}
            step="0.01"
            required
          />
        </div>
        <div className="mt-6 flex w-full justify-center">
          <button
            type="button"
            onClick={closeModalCb}
            className="h-8 rounded-md border border-transparent bg-secondaryColor px-2 py-1 text-center text-sm text-primaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-12 h-8 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-sm text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none"
          >
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

AddWasteContainerInfo.propTypes = {
  closeModalCb: PropTypes.func.isRequired,
};

export default AddWasteContainerInfo;
