import React, { useState, useEffect } from "react";
import PageWrapper from "../../components/PageWrapper";
import EditWasteContainerInfo from "../../components/EditWasteContainerInfo";
import AddWasteContainerInfo from "../../components/AddWasteContainerInfo";
import {
  fetchWasteContainerInfo,
  deleteWasteContainerInfo,
} from "../../api/quote";
import { currencyFormat, relativeTimeFormatter } from "../../lib/formatters";
import { MdModeEdit, MdDeleteForever } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import { useGlobalContext } from "../../GlobalContext";
import Modal from "../../components/Modal";
import { toast } from "react-toastify";

const WasteContainerInfo = () => {
  const { state } = useGlobalContext();
  const [wciList, setWciList] = useState(null);
  const [editingWci, setEditingWci] = useState(null);
  const [isAddingWci, setIsAddingWci] = useState(false);

  const loadData = async () => {
    try {
      const wasteContainerInfo = await fetchWasteContainerInfo(
        state.selectedCompany,
      );
      setWciList(wasteContainerInfo);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadData();
  }, [state.selectedCompany]);

  const th = "border-r-2 px-3 py-2";
  const td = "border-r-2 px-3 py-2 text-sm text-gray-900";

  return (
    <PageWrapper>
      <div className="size-full p-6 xl:p-8 2xl:p-10">
        <h1 className="mb-3 text-2xl font-bold text-black">
          Waste Container Info
        </h1>
        <button
          onClick={() => setIsAddingWci(true)}
          className="flex h-8 cursor-pointer gap-2 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-sm text-secondaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white"
        >
          <p className="h-fit">Create</p>
          <IoMdAddCircle className="m-1 text-green-500" />
        </button>
        <table className="my-6 w-full table-auto border-2 text-left text-sm text-gray-500">
          <thead className="bg-gray-100 text-xs uppercase text-gray-700">
            <tr>
              <th className={th}>Container Type</th>
              <th className={th}>Waste Type</th>
              <th className={th}>Excess Weight Charge</th>
              <th className={th}>Last Updated</th>
              <th className={th}>Updated By</th>
              <th className={th} />
              <th className={th} />
            </tr>
          </thead>
          <tbody>
            {wciList &&
              wciList.map((wci, index) => (
                <tr
                  key={`${wci.container_type}.${wci.waste_type}`}
                  className={`${index % 2 !== 0 && "bg-gray-100"}`}
                >
                  <td className={td}>{wci.container_type}</td>
                  <td className={td}>{wci.waste_type}</td>
                  <td className={td}>
                    {currencyFormat(wci.excess_weight_charge_gbp)}
                  </td>
                  <td className={td}>
                    {relativeTimeFormatter(wci.updated_at)}
                  </td>
                  <td className={td}>Jon McCormack</td>
                  <td className={td}>
                    <MdModeEdit
                      onClick={() => setEditingWci(wci)}
                      className="cursor-pointer text-lg text-blue-500"
                    />
                  </td>
                  <td className={td}>
                    <MdDeleteForever
                      onClick={() => {
                        deleteWasteContainerInfo(
                          state.selectedCompany,
                          wci.container_type,
                          wci.waste_type,
                        )
                          .then(() => {
                            loadData();
                            toast.success("Waste container deleted");
                          })
                          .catch((error) => {
                            console.error(error);
                            toast.error(
                              "Failed to delete Waste Container Info",
                            );
                          });
                      }}
                      className="cursor-pointer text-lg text-red-500"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Modal isOpen={editingWci !== null}>
        <EditWasteContainerInfo
          wasteContainerInfo={editingWci}
          closeModalCb={() => {
            loadData();
            setEditingWci(null);
          }}
        />
      </Modal>
      <Modal isOpen={isAddingWci}>
        <AddWasteContainerInfo
          closeModalCb={() => {
            loadData();
            setIsAddingWci(false);
          }}
        />
      </Modal>
    </PageWrapper>
  );
};

export default WasteContainerInfo;
