export const containerTypes = [
  { value: "240LTR", name: "240 Litre" },
  { value: "260LTR", name: "260 Litre" },
  { value: "660LTR", name: "660 Litre" },
  { value: "1100LTR", name: "1100 Litre" },
  { value: "6YARD", name: "6 Yard" },
  { value: "8YARD", name: "8 Yard" },
  { value: "10YARD", name: "10 Yard" },
  { value: "50BAGS", name: "50 Bags" },
  { value: "FEL", name: "FEL" },
  { value: "RORO", name: "RoRO" },
  { value: "M&V", name: "M&V" },
];

export const wasteTypes = [
  { value: "CRD", name: "CRD" },
  { value: "DMR", name: "DMR" },
  { value: "FOD", name: "FOD" },
  { value: "GEN", name: "GEN" },
  { value: "GLS", name: "GLS" },
  { value: "WEEE", name: "WEEE" },
  { value: "GAR", name: "GAR" },
  { value: "M&V", name: "M&V" },
];
export const frequencyLookup = {
  1: "Monthly",
  2.17: "Fortnightly",
  4.33: "Weekly",
};
export const weeklyFrequencyLookup = {
  4.33: "Once a week",
  8.67: "Twice a week",
  13: "Three times a week",
  17.33: "Four times a week",
  21.67: "Five times a week",
  26: "Six times a week",
  30.33: "Seven times a week",
};
export const wasteTypeNameLookup = {
  CRD: "Cardboard",
  DMR: "Dry Mixed Recycling",
  FOD: "Food",
  GEN: "General waste",
  GLS: "Glass",
  WEEE: "Electronics",
  GAR: "Garden",
  "M&V": "Man & Van",
};

export const daysLookup = [
  { text: "1 Day", value: 1 },
  { text: "3 Days", value: 3 },
  { text: "1 Week", value: 7 },
  { text: "2 Weeks", value: 14 },
  { text: "30 Days", value: 30 },
];
