import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import {
  createPriceListImpoort,
  getPriceListImports,
  getPublicPriceLists,
  deletePriceListImport,
} from "../../api/quote";
import { toast } from "react-toastify";
import { useGlobalContext } from "../../GlobalContext";
import { CiCirclePlus } from "react-icons/ci";
import { getCompanyPublicDetails } from "../../api/company";

const InputLabel = ({ text }) => (
  <label className="mr-2 bg-white text-sm italic text-gray-600 duration-100 ease-linear">
    {text}
  </label>
);

const PendingPriceListImport = ({
  publicPriceLists,
  importedPriceLists,
  supplierNames,
  onClose,
  selectedCompany,
}) => {
  const availablePriceLists = publicPriceLists.filter(
    (supplier) =>
      !importedPriceLists.map((ipl) => ipl.supplier_uuid).includes(supplier) &&
      supplier !== selectedCompany,
  );
  const [formData, setFormData] = useState({
    supplier: availablePriceLists[0],
    markupFactor: "1.00",
    redactSupplierDetails: true,
  });

  return (
    <div className="border p-2">
      <div className="flex justify-end">
        <button className="ml-auto" onClick={onClose}>
          <MdClose className="text-xl" />
        </button>
      </div>
      {availablePriceLists.length === 0 ? (
        <p className="mt-4 text-gray-600">
          No public price lists available to import.
        </p>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            createPriceListImpoort(
              selectedCompany,
              formData.supplier,
              Number(formData.markupFactor),
              formData.redactSupplierDetails,
            )
              .then(() => {
                onClose();
                toast.success("Price list imported successfully");
              })
              .catch((err) => {
                toast.error("Failed to import price list");
                console.error(err);
              });
          }}
        >
          <div className="mt-2 flex items-center gap-4">
            <p className="w-1/4">Supplier</p>
            <select
              className="border p-1"
              onChange={(e) =>
                setFormData((formData) => ({
                  ...formData,
                  supplier: e.target.value,
                }))
              }
              defaultValue={availablePriceLists[0]}
            >
              {availablePriceLists.map((supplierUuid) => (
                <option key={supplierUuid} value={supplierUuid}>
                  {supplierNames[supplierUuid]}
                </option>
              ))}
            </select>
          </div>
          <InputLabel text="The supplier who's price list you want to import" />
          <div className="mt-2 flex items-center gap-4">
            <p className="w-1/4">Markup Factor</p>
            <input
              type="text"
              className="w-14 border p-1"
              value={formData.markupFactor}
              onChange={(e) => {
                if (isNaN(e.target.value)) return;
                if (e.target.value.length > 4) return;
                setFormData((formData) => ({
                  ...formData,
                  markupFactor: e.target.value,
                }));
              }}
            />
          </div>
          <InputLabel text="The multiplication factor applied to prices imported from this price list in your quote builder" />
          <div className="relative mt-2 flex items-center gap-4">
            <p className="w-1/4">Redact supplier details?</p>
            <label className="relative cursor-pointer">
              <input
                type="checkbox"
                className="peer sr-only"
                checked={formData.redactSupplierDetails}
                disabled={false}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    redactSupplierDetails: e.target.checked,
                  });
                }}
              />
              <div className="peer relative h-6 w-11 cursor-pointer rounded-full bg-gray-200 p-1 after:absolute after:start-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800" />
            </label>
          </div>
          <InputLabel text="Whether to redact the supplier's details from quotes generated within your quote builder" />

          <div className="mt-4 flex justify-center">
            <button className="rounded bg-blue-500 p-2 text-white">
              Create Import
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

const ManageExternalPriceList = ({ onClose }) => {
  const { state } = useGlobalContext();

  const [importedPriceLists, setImportedPriceLists] = useState([]);
  const [publicPriceLists, setPublicPriceLists] = useState([]);
  const [priceListImportPending, setPriceListImportPending] = useState(false);
  const [supplierNames, setSupplierNames] = useState({});

  const loadSupplierNames = async () => {
    try {
      if (publicPriceLists.length < 1) return;
      const details = await getCompanyPublicDetails(publicPriceLists);
      const map = {};
      details.forEach((detail) => {
        map[detail.uuid] = detail.name;
      });
      setSupplierNames(map);
    } catch (error) {
      console.error(error);
    }
  };

  const loadPublicPriceLists = async () => {
    try {
      const publicPriceLists = await getPublicPriceLists();
      setPublicPriceLists(publicPriceLists);
    } catch (error) {
      toast.error("Failed to load public price lists");
      console.error(error);
    }
  };

  const loadPriceListImports = async () => {
    try {
      const importedPriceLists = await getPriceListImports(
        state.selectedCompany,
      );
      setImportedPriceLists(importedPriceLists);
    } catch (error) {
      toast.error("Failed to load imported price lists");
      console.error(error);
    }
  };

  useEffect(() => {
    loadPublicPriceLists();
    loadPriceListImports();
  }, []);

  useEffect(() => {
    loadSupplierNames();
  }, [publicPriceLists]);

  return (
    <div className="min-h-[600px] min-w-[400px] max-w-[900px] p-6">
      <div className="flex justify-end">
        <button className="ml-auto" onClick={onClose}>
          <MdClose className="text-xl" />
        </button>
      </div>
      <h1 className="mb-8 mt-2 text-2xl font-bold text-black">
        Imported Price Lists
      </h1>
      <p>
        Configure which company's you want to use as a price source in your
        quote generation.
      </p>
      {importedPriceLists.length === 0 && (
        <p className="mt-4 text-gray-600">
          No price lists imported yet. Quotes generated through your company's
          quote builder will exclusively use your own price list.
        </p>
      )}
      <div className="my-6">
        {importedPriceLists.map((priceList) => (
          <div
            key={priceList.supplier_uuid}
            className="flex items-center justify-between"
          >
            <div className="flex flex-col border p-2">
              <h2 className="text-lg font-bold">
                {supplierNames[priceList.supplier_uuid]}
              </h2>
              <div>
                <p className="text-sm text-gray-800">
                  Markup Factor: {priceList.markup_factor.toFixed(2)}
                </p>
                <p className="text-sm text-gray-800">
                  Redact Supplier Details:{" "}
                  {priceList.redact_supplier_details ? "Yes" : "No"}
                </p>
                <button
                  className="text-sm text-red-500"
                  onClick={() => {
                    deletePriceListImport(
                      state.selectedCompany,
                      priceList.supplier_uuid,
                    )
                      .then(() => {
                        loadPriceListImports();
                        toast.success("Price list deleted successfully");
                      })
                      .catch((err) => {
                        toast.error("Failed to delete price list");
                        console.error(err);
                      });
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {priceListImportPending && (
        <PendingPriceListImport
          importedPriceLists={importedPriceLists}
          publicPriceLists={publicPriceLists}
          supplierNames={supplierNames}
          onClose={() => {
            setPriceListImportPending(false);
            loadPriceListImports();
          }}
          selectedCompany={state.selectedCompany}
        />
      )}
      <button
        className="mt-6 flex items-center gap-1 text-blue-500 disabled:text-gray-400"
        onClick={() => {
          if (priceListImportPending) return;
          setPriceListImportPending(true);
        }}
        disabled={priceListImportPending}
      >
        <CiCirclePlus className="text-3xl" />
        <p className="text-base">Import a public price list</p>
      </button>
    </div>
  );
};

export default ManageExternalPriceList;
