import React from "react";
import { relativeTimeFormatter } from "../../lib/formatters";

const UserDetails = ({
  selectedUserCompanies,
  selectedUserDetails,
  setIsModalOpen,
}) => {
  return (
    <div className="flex min-h-[40vh] min-w-[50vw] flex-col items-center justify-between p-10 xl:p-12 2xl:p-16">
      <h2 className="text-center text-lg font-bold xl:text-xl 2xl:text-2xl">
        {selectedUserDetails?.name}'s Companies
      </h2>
      {selectedUserCompanies.length === 0 ? (
        <p className="text-center text-base xl:text-lg 2xl:text-xl">
          {selectedUserDetails?.name} is not registered to any companies.
        </p>
      ) : (
        <div className="p-4 xl:p-6 2xl:p-8">
          <table className="w-full table-auto border-2 text-left text-sm text-gray-500">
            <thead className="bg-gray-100 text-xs uppercase text-gray-700">
              <tr>
                <th className="border-r-2 px-3 py-2 xl:px-6 xl:py-3">Name</th>
                <th className="border-r-2 px-3 py-2 xl:px-6 xl:py-3">
                  Address
                </th>
                <th className="border-r-2 px-3 py-2 xl:px-6 xl:py-3">Email</th>
                <th className="border-r-2 px-3 py-2 xl:px-6 xl:py-3">
                  Phone Number
                </th>
                <th className="border-r-2 px-3 py-2 xl:px-6 xl:py-3">
                  Website
                </th>
                <th className="border-r-2 px-3 py-2 xl:px-6 xl:py-3">Types</th>
                <th className="border-r-2 px-3 py-2 xl:px-6 xl:py-3">
                  Created
                </th>
                <th className="px-3 py-2 xl:px-6 xl:py-3">Updated</th>
              </tr>
            </thead>
            <tbody>
              {selectedUserCompanies.map((company, index) => (
                <tr
                  key={index}
                  className={`${index % 2 !== 0 && "bg-gray-100"}`}
                >
                  <td className="border-r-2 px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3 xl:text-base">
                    {company.name}
                  </td>
                  <td className="border-r-2 px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3 xl:text-base">
                    {company.address_line_1}
                    <br />
                    {company.address_line_2 && (
                      <>
                        {company.address_line_2}
                        <br />
                      </>
                    )}
                    {company.town}
                    <br />
                    {company.county}
                    <br />
                    {company.postcode}
                    <br />
                  </td>
                  <td className="border-r-2 px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3 xl:text-base">
                    {company.contact_email}
                  </td>
                  <td className="border-r-2 px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3 xl:text-base">
                    {company.contact_phone_number}
                  </td>
                  <td className="border-r-2 px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3 xl:text-base">
                    {company.website}
                  </td>
                  <td className="border-r-2 px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3 xl:text-base">
                    {company.types.map((type, index) => {
                      return (
                        <p className="block leading-10" key={index}>
                          {type}
                        </p>
                      );
                    })}
                  </td>{" "}
                  <td className="border-r-2 px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3 xl:text-base">
                    {relativeTimeFormatter(company.created_at)}
                  </td>
                  <td className="px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3 xl:text-base">
                    {relativeTimeFormatter(company.updated_at)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <button
        onClick={() => setIsModalOpen(false)}
        type="button"
        className="h-8 w-1/5 rounded-md border border-transparent bg-secondaryColor px-2 py-1 text-center text-sm text-primaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white xl:h-12 xl:px-4 xl:py-2 xl:text-xl"
      >
        Close
      </button>
    </div>
  );
};
export default UserDetails;
