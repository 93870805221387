import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import {
  getCompany,
  getSystemUserDetails,
  getSystemUsers,
  updateUserSystemRole,
} from "../../api/company";
import Modal from "../../components/Modal";
import UserDetails from "../../components/UserDetails";
import PaginationControls from "../../components/PaginationControls";
import { formatSystemRole, singleLineAddress } from "../../lib/formatters";
import { MdCheck, MdOutlineModeEditOutline } from "react-icons/md";
import { toast } from "react-toastify";
const SystemUsers = () => {
  const [systemUsers, setSystemUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [selectedUserCompanies, setSelectedUserCompanies] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [editingRoleUuids, setEditingRoleUuids] = useState([]);

  useEffect(() => {
    const fetchSystemUsers = async () => {
      const users = await getSystemUsers();
      setSystemUsers(users);
      setFilteredUsers(users);
      setPageCount(Math.ceil(users.length / 15));
    };
    fetchSystemUsers();
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userDetails = await getSystemUserDetails(selectedUser.uuid);
      setSelectedUserDetails(userDetails);
      let userCompanies = [];
      if (userDetails.user_company_roles) {
        userCompanies = await Promise.all(
          userDetails.user_company_roles.map((ucr) =>
            getCompany(ucr.company_uuid),
          ),
        );
      }
      setSelectedUserCompanies(userCompanies);
    };
    selectedUser && fetchUserDetails();
  }, [selectedUser]);

  useEffect(() => {
    const users = systemUsers.filter((user) => {
      return (
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilteredUsers(users);

    setPageCount(Math.ceil(users.length / 15));
  }, [searchTerm]);

  const onSearchChange = (e) => {
    setPageNumber(1);
    setSearchTerm(e.target.value);
  };

  const onSubmitRoleEdit = async (formData, user, index) => {
    try {
      const systemRole = formData.get("system_role");
      await updateUserSystemRole(user?.uuid, systemRole);
      setEditingRoleUuids((prevUuids) => {
        const filteredUuids = prevUuids.filter(
          (element) => element !== user?.uuid,
        );
        return filteredUuids;
      });
      setFilteredUsers((prevUsers) => {
        const newUsers = [...prevUsers];
        newUsers[(pageNumber - 1) * 15 + index].system_role = systemRole;
        return newUsers;
      });
      toast.success("Your changes have been saved.");
    } catch {
      toast.error("Sorry that didn't work. Please try again.");
    }
  };

  const th = "border-r-2 px-3 py-2";
  const td = "border-r-2 px-3 py-2 text-sm text-gray-900";

  return (
    <PageWrapper>
      <div className="size-full p-6 xl:p-8 2xl:p-10">
        <h1 className="mb-3 block text-2xl font-bold text-black">
          System Users
        </h1>
        <div className="mb-3 flex w-full items-center justify-between gap-4">
          <input
            name="search"
            id="search"
            type="text"
            placeholder="Search name or email"
            onChange={onSearchChange}
            className="h-8 w-1/4 appearance-none rounded border-2 px-3 py-2 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring 2xl:h-10 2xl:text-base"
          />

          <PaginationControls
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageCount={pageCount}
          />
        </div>
        <table className="mb-3 w-full table-auto border-2 text-left text-sm text-gray-500">
          <thead className="bg-gray-100 text-xs uppercase text-gray-700">
            <tr>
              <th className={th}>Name</th>
              <th className={th}>Address</th>
              <th className={th}>Email</th>
              <th className={th}>Phone Number</th>
              <th className={th}>System Role</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers
              ?.slice((pageNumber - 1) * 15, (pageNumber - 1) * 15 + 15)
              .map((user, index) => (
                <tr
                  key={index}
                  className={`${index % 2 !== 0 && "bg-gray-100"}`}
                >
                  <td className={td}>
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedUser(user);
                        setIsModalOpen(true);
                      }}
                      className="text-blue-800 hover:text-blue-500 active:text-blue-400"
                    >
                      {user.name}
                    </button>
                  </td>
                  <td className={td}>{singleLineAddress(user)}</td>
                  <td className={td}>{user.email}</td>
                  <td className={td}>{user.phone_number}</td>
                  <td className="flex justify-between px-3 py-2 text-sm text-gray-900">
                    {editingRoleUuids.includes(user.uuid) ? (
                      <>
                        <form
                          action={(formData) =>
                            onSubmitRoleEdit(formData, user, index)
                          }
                          className="flex w-full justify-between"
                        >
                          <select
                            name="system_role"
                            defaultValue={user.system_role}
                            className="ring"
                          >
                            <option value={"user"}>User</option>
                            <option value={"admin"}>Admin</option>
                          </select>
                          <button type="submit">
                            <MdCheck className="text-lg text-green-800 hover:text-green-500 xl:text-xl 2xl:text-2xl" />
                          </button>
                        </form>
                      </>
                    ) : (
                      <>
                        {formatSystemRole(user.system_role)}
                        <button
                          onClick={() =>
                            setEditingRoleUuids((prevUuids) => {
                              const newUuids = [...prevUuids];
                              newUuids.push(user.uuid);
                              return newUuids;
                            })
                          }
                        >
                          <MdOutlineModeEditOutline className="text-lg text-blue-800 hover:text-blue-500 xl:text-xl 2xl:text-2xl" />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <PaginationControls
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageCount={pageCount}
        />
        <Modal isOpen={isModalOpen}>
          <UserDetails
            selectedUserCompanies={selectedUserCompanies}
            selectedUserDetails={selectedUserDetails}
            setIsModalOpen={setIsModalOpen}
          />
        </Modal>
      </div>
    </PageWrapper>
  );
};
export default SystemUsers;
