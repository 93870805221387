import React, { useState, useEffect } from "react";
import PageWrapper from "../../components/PageWrapper";
import ManageCompany from "../../components/ManageCompany";
import Modal from "../../components/Modal";
import { getAllCompanies } from "../../api/company";
import { toast } from "react-toastify";
import { relativeTimeFormatter } from "../../lib/formatters";

const CompanyManagement = () => {
  const [companies, setCompanies] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const fetchCompanies = async () => {
    try {
      const companies = await getAllCompanies();
      setCompanies(companies);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch companies");
    }
  };

  useEffect(() => {
    fetchCompanies();
    setInterval(() => {
      fetchCompanies();
    }, 60000);
  }, []);

  return (
    <PageWrapper>
      <div className="p-6 xl:p-8 2xl:p-10">
        <h1 className="mb-3 block text-2xl font-bold xl:mb-5">
          Company Management
        </h1>
        <div className="flex flex-wrap">
          {companies ? (
            companies.map((company) => (
              <div
                key={company.uuid}
                className="m-3 w-full min-w-[200px] max-w-[400px] cursor-pointer border border-gray-300 p-3"
                onClick={() => setSelectedCompany(company)}
              >
                <div className="flex w-full items-center space-x-4">
                  <h2 className="my-1 text-xl font-bold">{company.name}</h2>
                  {company.active ? (
                    <p className="rounded bg-green-300 p-1">Active</p>
                  ) : (
                    <p className="rounded bg-red-300 p-1">Not Active</p>
                  )}
                </div>
                <p className="my-1 text-lg">
                  {company.county} - {company.postcode}
                </p>
                <p className="my-1 text-sm italic text-gray-500">
                  Created: {relativeTimeFormatter(company.created_at)}, Updated:{" "}
                  {relativeTimeFormatter(company.updated_at)}
                </p>
              </div>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
      {selectedCompany !== null && (
        <Modal isOpen>
          <ManageCompany
            companyUuid={selectedCompany.uuid}
            onClose={() => {
              fetchCompanies();
              setSelectedCompany(null);
            }}
          />
        </Modal>
      )}
    </PageWrapper>
  );
};

export default CompanyManagement;
