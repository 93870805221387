import React from "react";
import PageWrapper from "../../components/PageWrapper";
import WhitelabelDomain from "../../components/WhitelabelDomain";
import WhitelabelStyle from "../../components/WhitelabelStyle";

const Whitelabel = () => {
  return (
    <PageWrapper>
      <div className="size-full p-6 xl:p-8 2xl:p-10">
        <div>
          <h1 className="mb-3 text-2xl font-bold text-black">Whitelabel</h1>
          <p className="mb-3 block text-lg text-black">
            Configuration to enable a subdomain on your companies website to be
            served by Switch Waste's tailored quote builder.
          </p>
        </div>
        <div className="flex w-full flex-col gap-4 md:flex-row">
          <WhitelabelDomain />
          <WhitelabelStyle />
        </div>
      </div>
    </PageWrapper>
  );
};

export default Whitelabel;
