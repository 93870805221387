import React from "react";
import QuoteDetailsInternal from "../../../components/QuoteDetailsInternal";
import PageWrapper from "../../../components/PageWrapper";

const QuoteDetails = () => {
  return (
    <PageWrapper>
      <div className="size-full p-6 xl:p-8 2xl:p-10">
        <QuoteDetailsInternal />
      </div>
    </PageWrapper>
  );
};

export default QuoteDetails;
