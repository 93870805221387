import React, { useState } from "react";
import { FaRegUser } from "react-icons/fa";
import {
  redirectToLogin,
  redirectToRegister,
  isLoggedIn,
  getEmail,
  logout,
  getSystemRole,
} from "../../lib/auth";
import PropTypes from "prop-types";
import { useGlobalContext } from "../../GlobalContext";
import { formatSystemRole } from "../../lib/formatters";
import ENV from "../../lib/config";

const AccountButton = ({ clientId, loginUrl }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { resetState } = useGlobalContext();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleLogin = () => {
    redirectToLogin(clientId, loginUrl);
  };

  const handleRegister = () => {
    redirectToRegister(clientId, ENV.get().REACT_APP_REGISTER_URL);
  };

  const handleLogout = () => {
    resetState();
    logout();
    setIsModalOpen(false);
  };

  return (
    <div className="relative">
      <button onClick={toggleModal} className="">
        <FaRegUser className="text-xl text-secondaryColor" />
      </button>
      {isModalOpen && (
        <div className="absolute right-5 top-5 rounded bg-secondaryColor p-3">
          {isLoggedIn() ? (
            <div className="flex flex-col items-center gap-1">
              <p className="text-lg text-primaryColor">{getEmail()}</p>
              <p className="text-base text-primaryColor">
                {formatSystemRole(getSystemRole())}
              </p>
              <button
                onClick={handleLogout}
                className="rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-lg text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none"
              >
                Logout
              </button>
            </div>
          ) : (
            <>
              <button
                onClick={handleLogin}
                className="mb-3 w-full rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-lg text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none"
              >
                Login
              </button>
              <button
                onClick={handleRegister}
                className="rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-lg text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none"
              >
                Register
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

PropTypes.propTypes = {
  clientId: PropTypes.string.isRequired,
  loginUrl: PropTypes.string.isRequired,
};

export default AccountButton;
