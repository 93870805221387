import { getCompanyApiUrl } from "./main";
import { getAccessToken } from "./../lib/auth";

export const getCompanies = async () => {
  const accessToken = getAccessToken();

  const response = await fetch(getCompanyApiUrl("/company"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch companies");
  }
};

export const getCompany = async (uuid) => {
  const accessToken = getAccessToken();
  const response = await fetch(
    getCompanyApiUrl("/company/" + encodeURIComponent(uuid)),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
};

export const getCompanyRole = async (companyUuid) => {
  const accessToken = getAccessToken();
  const response = await fetch(
    getCompanyApiUrl(
      "/company/" + encodeURIComponent(companyUuid) + "/company-role",
    ),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (response.ok) {
    return (await response.json()).company_role;
  } else {
    throw response;
  }
};

export const getCompanyUsers = async (companyUuid) => {
  const accessToken = getAccessToken();
  const response = await fetch(
    getCompanyApiUrl("/company/" + encodeURIComponent(companyUuid) + "/users"),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch company users");
  }
};

export const addCompanyUser = async (companyUuid, email, companyRole) => {
  const accessToken = getAccessToken();
  const response = await fetch(
    getCompanyApiUrl("/company/" + encodeURIComponent(companyUuid) + "/user"),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ user_email: email, company_role: companyRole }),
    },
  );

  if (response.ok) {
    return;
  } else {
    switch (response.status) {
      case 409:
        throw new Error("User is already a company user");
      case 404:
        throw new Error("User doesn't exist");
      default:
        throw new Error("Failed to add company user");
    }
  }
};

export const removeCompanyUser = async (companyUuid, userUuid) => {
  const accessToken = getAccessToken();
  const response = await fetch(
    getCompanyApiUrl(
      "/company/" +
        encodeURIComponent(companyUuid) +
        "/user/" +
        encodeURIComponent(userUuid),
    ),
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (!response.ok) {
    throw new Error("Failed to remove company user");
  }
};

export const getSystemUsers = async () => {
  const accessToken = getAccessToken();

  const response = await fetch(getCompanyApiUrl("/user"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch users");
  }
};

export const getSystemUserDetails = async (userUuid) => {
  const accessToken = getAccessToken();

  const response = await fetch(
    getCompanyApiUrl("/user/" + encodeURIComponent(userUuid)),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch user details");
  }
};

export const getAllCompanies = async () => {
  const accessToken = getAccessToken();
  const response = await fetch(getCompanyApiUrl("/company/all"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch all companies");
  }
};

export const updateCompany = async (companyUuid, data) => {
  const accessToken = getAccessToken();
  const response = await fetch(getCompanyApiUrl("/company/" + companyUuid), {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error("Failed to update company");
  }
};

export const createCompany = async (formData) => {
  const accessToken = getAccessToken();
  const response = await fetch(getCompanyApiUrl("/company"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    throw new Error("Failed to create company");
  }

  return await response.json().uuid;
};

export const updateUserSystemRole = async (userUuid, systemRole) => {
  const accessToken = getAccessToken();
  const response = await fetch(
    getCompanyApiUrl("/user/" + userUuid + "/role"),
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ system_role: systemRole }),
    },
  );

  if (!response.ok) {
    throw new Error("Failed to update user role");
  }
};

export const getCompanyPublicDetails = async (companies) => {
  const query = new URLSearchParams();
  companies.forEach((company) => query.append("company", company));
  const response = await fetch(
    getCompanyApiUrl(`/company/public-details?${query.toString()}`),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
};
