import ENV from "./config";

export const OAUTH_STATE_KEY = "oauth_state";
export const PKCE_CODE_VERIFIER_KEY = "pkce_code_verifier";
export const AUTH_TOKENS_KEY = "auth_tokens";

export const generateOAuthParams = async (oauth2ClientId, redirectUri) => {
  // generate a code challenge
  const codeVerifier = crypto.getRandomValues(new Uint8Array(32));

  // hash the verifier with sha256
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  // store the code verifier in local storage
  localStorage.setItem(PKCE_CODE_VERIFIER_KEY, codeVerifier);

  const hash = await crypto.subtle.digest("SHA-256", data);

  // convert the hash to base64
  const hashArray = Array.from(new Uint8Array(hash));
  const hashStr = hashArray.map((b) => String.fromCharCode(b)).join("");
  const code_challenge = btoa(hashStr)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

  const state = crypto.randomUUID();
  localStorage.setItem(OAUTH_STATE_KEY, state);

  // create the OAuth2 params
  const params = {
    client_id: oauth2ClientId,
    redirect_uri: redirectUri,
    response_type: "code",
    scope: "all",
    state,
    code_challenge,
    code_challenge_method: "S256",
  };

  return params;
};

export const redirectToLogin = async (clientId, loginUrl) => {
  const oauth2Params = await generateOAuthParams(
    clientId,
    window.location.origin + "/oauth-callback",
  );
  const oauthUrl = `${loginUrl}?${new URLSearchParams(
    oauth2Params,
  ).toString()}`;
  window.location.href = oauthUrl;
};

export const redirectToRegister = async (clientId, registerUrl) => {
  const oauth2Params = await generateOAuthParams(
    clientId,
    window.location.origin + "/oauth-callback",
  );
  const oauthUrl = `${registerUrl}?${new URLSearchParams(
    oauth2Params,
  ).toString()}`;
  window.location.href = oauthUrl;
};

export const handleOAuthCallback = async (
  clientId,
  tokenUrl,
  postLoginRedirect,
) => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const state = urlParams.get("state");
  const storedState = localStorage.getItem(OAUTH_STATE_KEY);

  if (state !== storedState) {
    throw new Error("Invalid state parameter");
  }

  if (!code) {
    throw new Error("No authorization code found");
  }

  const params = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append("code", code);
  params.append("client_id", clientId);
  params.append("code_verifier", localStorage.getItem(PKCE_CODE_VERIFIER_KEY));
  params.append("redirect_uri", window.location.origin + "/oauth-callback");
  const response = await fetch(tokenUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: params.toString(),
  });
  if (!response.ok) {
    throw new Error(`Failed to log in: status ${response.status}`);
  }

  localStorage.setItem(AUTH_TOKENS_KEY, JSON.stringify(await response.json()));
  window.history.replaceState({}, document.title, window.location.pathname); // Remove code from URL
  if (postLoginRedirect) {
    window.location.href = postLoginRedirect;
  }
};

export const isLoggedIn = () => {
  const exp = getAccessTokenPayload()?.exp;
  if (exp === undefined) {
    return false;
  }
  return exp * 1000 > Date.now();
};

export const logout = () => {
  localStorage.removeItem(AUTH_TOKENS_KEY);
  // force refresh
  window.location.href = "/";
};

export const getAccessTokenPayload = () => {
  try {
    const accessToken = getAccessToken();

    const payload = accessToken.split(".")[1];
    const decoded = atob(payload);
    const parsed = JSON.parse(decoded);
    if (parsed.exp * 1000 < Date.now()) {
      return null;
    }
    return parsed;
  } catch {
    return null;
  }
};

export const getEmail = () => {
  return getAccessTokenPayload()?.email;
};

export const getSystemRole = () => {
  return getAccessTokenPayload()?.system_role;
};

export const getAccessToken = () => {
  try {
    const accessToken = JSON.parse(
      localStorage.getItem(AUTH_TOKENS_KEY),
    ).access_token;
    const payload = accessToken.split(".")[1];
    const decoded = atob(payload);
    const parsed = JSON.parse(decoded);
    if (parsed.exp * 1000 < Date.now()) {
      return null;
    }
    return accessToken;
  } catch {
    return null;
  }
};

export const refreshAccessToken = async () => {
  const refreshToken = JSON.parse(
    localStorage.getItem(AUTH_TOKENS_KEY),
  ).refresh_token;
  const params = new URLSearchParams();
  params.append("grant_type", "refresh_token");
  params.append("refresh_token", refreshToken);
  params.append("client_id", ENV.get().REACT_APP_OAUTH2_CLIENT_ID);
  const response = await fetch(ENV.get().REACT_APP_OAUTH2_TOKEN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: params.toString(),
  });
  if (!response.ok) {
    throw new Error(`Failed to refresh token: status ${response.status}`);
  }
  localStorage.setItem(AUTH_TOKENS_KEY, JSON.stringify(await response.json()));
};
