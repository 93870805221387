import React, { useState, useEffect } from "react";
import PageWrapper from "../../components/PageWrapper";
import { useGlobalContext } from "../../GlobalContext";
import { getCompanyUsers } from "../../api/company";
import { relativeTimeFormatter } from "../../lib/formatters";
import { FaUserPen, FaUserMinus } from "react-icons/fa6";
import AddCompanyUser from "../../components/AddCompanyUser";
import ConfirmationModal from "../../components/ConfirmationModal";
import { removeCompanyUser } from "../../api/company";
import { formatCompanyRole } from "../../lib/formatters";
import PaginationControls from "../../components/PaginationControls";
import Modal from "../../components/Modal";

const CompanyUsers = () => {
  const { state } = useGlobalContext();
  const [companyUsers, setCompanyUsers] = useState([]);
  const [showAddUser, setShowAddUser] = useState(false);
  const [deletingUser, setDeletingUser] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const onSearchChange = (e) => {
    setPageNumber(1);
    setSearchTerm(e.target.value);
  };
  const refreshUsers = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    const loadCompanyUsers = async () => {
      try {
        const users = await getCompanyUsers(state.selectedCompany);
        setCompanyUsers(users);
        setFilteredUsers(users);
        setPageCount(Math.ceil(users.length / 15));
      } catch (error) {
        console.error("Failed to load company users", error);
      }
    };
    loadCompanyUsers();
  }, [state.selectedCompany, refresh]);

  useEffect(() => {
    const users = companyUsers.filter((user) => {
      return (
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilteredUsers(users);

    setPageCount(Math.ceil(users.length / 15));
  }, [searchTerm]);

  const th = "border-r-2 px-3 py-2";
  const td = "border-r-2 px-3 py-2 text-sm text-gray-900";

  return (
    <PageWrapper>
      <div className="size-full p-6 xl:p-8 2xl:p-10">
        {companyUsers.length === 0 ? (
          <p>No users found</p>
        ) : (
          <>
            <div className="relative">
              <h1 className="mb-3 block text-2xl font-bold text-black">
                Company Users
              </h1>
              <button
                type="button"
                onClick={() => setShowAddUser(true)}
                className="absolute right-0 top-0 ml-auto h-8 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-sm text-secondaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white"
              >
                Add User
              </button>
            </div>
            <div className="mb-3 flex w-full items-center justify-between gap-4 lg:gap-6 xl:mb-4 xl:gap-8 2xl:mb-5">
              <input
                name="search"
                id="search"
                type="text"
                placeholder="Search name or email"
                onChange={onSearchChange}
                className="h-8 w-1/4 appearance-none rounded border-2 px-3 py-2 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring 2xl:h-10 2xl:text-base"
              />

              <PaginationControls
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                pageCount={pageCount}
              />
            </div>
            <table className="mb-3 w-full table-auto border-2 text-left text-sm text-gray-500">
              <thead className="bg-gray-100 text-xs uppercase text-gray-700">
                <tr>
                  <th className={th}>Name</th>
                  <th className={th}>Email</th>
                  <th className={th}>Company Role</th>
                  <th className={th}>Modified</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers
                  ?.slice((pageNumber - 1) * 15, (pageNumber - 1) * 15 + 15)
                  .map((user, index) => (
                    <tr
                      key={index}
                      className={`${index % 2 !== 0 && "bg-gray-100"}`}
                    >
                      <td className={td}>
                        <div className="flex justify-between">
                          {user.name}
                          <div className="flex gap-2 xl:gap-3 2xl:gap-4">
                            <FaUserPen className="cursor-pointer text-base text-green-800 hover:text-green-500 xl:text-lg" />
                            <FaUserMinus
                              className="cursor-pointer text-base text-red-800 hover:text-red-500 xl:text-lg"
                              onClick={() => {
                                setDeletingUser(user);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                      <td className={td}>{user.email}</td>
                      <td className={td}>
                        {formatCompanyRole(user.company_role)}
                      </td>
                      <td className={td}>
                        {relativeTimeFormatter(user.updated_at)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <PaginationControls
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              pageCount={pageCount}
            />
          </>
        )}
      </div>
      <ConfirmationModal
        isOpen={deletingUser !== null}
        question={`Remove ${deletingUser?.name} (${deletingUser?.email}) from this company?`}
        onClose={() => setDeletingUser(null)}
        onConfirm={async () => {
          try {
            await removeCompanyUser(state.selectedCompany, deletingUser.uuid);
            setCompanyUsers(
              companyUsers.filter((u) => u.uuid !== deletingUser.uuid),
            );
            setDeletingUser(null);
          } catch (error) {
            console.error("Failed to delete user", error);
          }
        }}
      />
      <Modal isOpen={showAddUser}>
        <AddCompanyUser
          closeModalCb={() => {
            setShowAddUser(false);
            refreshUsers();
          }}
        />
      </Modal>
    </PageWrapper>
  );
};

export default CompanyUsers;
