import React, { useEffect, useState, useRef } from "react";
import { extractAddressParts } from "../../lib/google-maps";
import { useNavigate, useOutletContext } from "react-router-dom";
import { acceptQuote } from "../../api/quote";
import { toast } from "react-toastify";
import { generateQuotePDFBytes } from "../../lib/pdf";

const CustomerDetailsCollection = () => {
  const context = useOutletContext();
  const navigate = useNavigate();
  const setCustomerDetails = context[0];
  const customerDetails = context[1];
  const services = context[2];
  const desiredQuote = context[9];
  const quoteSessionUUID = context[11];
  const setPDFBytes = context[14];
  const inputRef = useRef(null);
  const [formData, setFormData] = useState(customerDetails);
  const [formValidation, setFormValidation] = useState({
    name: null,
    email: null,
    address_line_1: null,
    town: null,
    postcode: null,
    county: null,
  });
  useEffect(() => {
    if (customerDetails?.postcode === "") {
      navigate("/quote/postcode");
      return;
    } else if (services.length === 0) {
      navigate("/quote/waste-services");
      return;
    } else if (customerDetails?.email === "") {
      navigate("/quote/email");
      return;
    } else if (!desiredQuote) {
      navigate("/quote/quote-selection");
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValidName = validateName();
    const isValidEmail = validateEmail();
    const isValidAddressLine1 = validateAddressLine1();
    const isValidTown = validateTown();
    const isValidCounty = validateCounty();
    const isValidPostcode = validatePostcode();

    if (
      !isValidName ||
      !isValidEmail ||
      !isValidAddressLine1 ||
      !isValidTown ||
      !isValidCounty ||
      !isValidPostcode
    ) {
      return;
    }
    setCustomerDetails(formData);
    try {
      const newPDFBytes = await generateQuotePDFBytes(
        formData?.name,
        desiredQuote,
      );
      await acceptQuote(
        formData,
        quoteSessionUUID,
        desiredQuote.quote_uuid,
        newPDFBytes,
      );

      setPDFBytes(newPDFBytes);
      navigate("/quote/accepted");
    } catch {
      toast("Error accepting quote. Please try again.");
    }
  };

  const validateName = () => {
    if (formData.name) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        name: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        name: false,
      }));
      return false;
    }
  };

  const handleNameChange = (e) => {
    formValidation.name === false &&
      e.target.value &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        name: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      name: e.target.value,
    }));
  };
  const validateEmail = () => {
    const emailRegex =
      // TODO(SW-25)
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    if (emailRegex.test(formData.email)) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        email: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        email: false,
      }));
      return false;
    }
  };

  const handleEmailChange = (e) => {
    const emailRegex =
      // TODO(SW-25)
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    formValidation.email === false &&
      emailRegex.test(e.target.value) &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        email: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      email: e.target.value,
    }));
  };

  const validateAddressLine1 = () => {
    if (formData.address_line_1) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        address_line_1: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        address_line_1: false,
      }));
      return false;
    }
  };

  const handleAddressLine1Change = (e) => {
    formValidation.address_line_1 === false &&
      e.target.value &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        address_line_1: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      address_line_1: e.target.value,
    }));
  };

  const validateTown = () => {
    if (formData.town) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        town: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        town: false,
      }));
      return false;
    }
  };

  const handleTownChange = (e) => {
    formValidation.town === false &&
      e.target.value &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        town: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      town: e.target.value,
    }));
  };

  const validateCounty = () => {
    if (formData.county) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        county: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        county: false,
      }));
      return false;
    }
  };

  const handleCountyChange = (e) => {
    formValidation.county === false &&
      e.target.value &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        county: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      county: e.target.value,
    }));
  };

  const validatePostcode = () => {
    const postcodeRegex =
      /^([a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?)\s?[0-9][a-zA-Z]{2}?/;
    if (postcodeRegex.test(formData.postcode)) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        postcode: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        postcode: false,
      }));
      return false;
    }
  };

  useEffect(() => {
    if (!window.google) return; // Ensure the Google script has loaded

    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: ["geocode"],
        componentRestrictions: { country: "GB" },
      },
    );

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userLocation = new window.google.maps.LatLng(
            latitude,
            longitude,
          );

          // Bias the autocomplete results to the user's location
          autocomplete.setBounds(
            new window.google.maps.LatLngBounds(userLocation),
          );
        },
        (error) => {
          console.error("Error getting user's location:", error);
        },
      );
    }

    inputRef.current.placeholder = "Start typing your address";

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.address_components) {
        const components = place.address_components;
        const addressParts = extractAddressParts(components);
        setFormData((prevData) => ({
          ...prevData,
          address_line_1: addressParts.addressLine1,
          address_line_2: addressParts.addressLine2,
          town: addressParts.town,
          county: addressParts.county,
        }));
      }
    });
  }, []);

  return (
    <div className="flex w-full justify-center">
      <div className="w-full px-6 py-5 md:w-5/6 lg:w-8/12 lg:py-10">
        <h2 className="mb-2 block text-center text-base font-bold text-black lg:text-xl">
          Personal Details
        </h2>
        <p className="mb-3 text-center text-xs text-black lg:mb-6 lg:text-lg">
          Please provide your contact details and address so we can confirm the
          quote.
        </p>
        <form onSubmit={handleSubmit} noValidate>
          <div className="relative mb-1 lg:mb-2">
            <input
              type="text"
              id="name"
              className={`peer appearance-none border-2 shadow ${formValidation.name ? "border-successColor ring-successColor" : formValidation.name === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
              name="name"
              value={formData.name || ""}
              onChange={handleNameChange}
              onBlur={validateName}
              required
              placeholder="Name"
            />
            <label
              htmlFor="name"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
            >
              Name
            </label>
            <p
              className={`text-xs italic text-errorColor lg:text-sm ${formValidation.name !== false && "invisible"}`}
            >
              Please provide a valid name.
            </p>
          </div>
          <div className="relative mb-3.5 lg:mb-7">
            <input
              type="text"
              id="company_name"
              name="company_name"
              className="peer h-8 w-full appearance-none rounded border-2 px-2 py-1 text-xs leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base"
              value={formData.company_name}
              onChange={handleChange}
              placeholder="Company name"
            />
            <label
              htmlFor="company_name"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
            >
              Company Name (optional)
            </label>
          </div>
          <div className="relative mb-1 lg:mb-2">
            <input
              type="email"
              id="email"
              name="email"
              className={`peer appearance-none border-2 shadow ${formValidation.email ? "border-successColor ring-successColor" : formValidation.email === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
              value={formData.email}
              onChange={handleEmailChange}
              placeholder="Email"
              onBlur={validateEmail}
              required
            />
            <label
              htmlFor="email"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
            >
              Email
            </label>
            <p
              className={`text-xs italic text-errorColor lg:text-sm ${formValidation.email !== false && "invisible"}`}
            >
              Please provide a valid email.
            </p>
          </div>
          <div className="relative mb-3.5 lg:mb-7">
            <input
              type="tel"
              id="phone"
              name="phone"
              className="peer h-8 w-full appearance-none rounded border-2 px-2 py-1 text-xs leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone"
            />
            <label
              htmlFor="phone"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
            >
              Phone (optional)
            </label>
          </div>
          <div className="relative mb-1 lg:mb-2">
            <input
              type="text"
              id="address_line_1"
              className={`peer appearance-none border-2 shadow ${formValidation.address_line_1 ? "border-successColor ring-successColor" : formValidation.address_line_1 === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring focus:placeholder:text-gray-500 lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
              name="address_line_1"
              ref={inputRef}
              value={formData.address_line_1 || ""}
              onChange={handleAddressLine1Change}
              onBlur={validateAddressLine1}
              required
              placeholder="Address Line 1"
            />
            <label
              htmlFor="address_line_1"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
            >
              Address Line 1
            </label>
            <p
              className={`text-xs italic text-errorColor lg:text-sm ${formValidation.address_line_1 !== false && "invisible"}`}
            >
              Please provide a valid address.
            </p>
          </div>
          <div className="relative mb-3.5 lg:mb-7">
            <input
              type="text"
              id="address_line_2"
              className="peer h-8 w-full appearance-none rounded border-2 px-2 py-1 text-xs leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base"
              name="address_line_2"
              value={formData.address_line_2 || ""}
              onChange={handleChange}
              placeholder="Address Line 2"
            />
            <label
              htmlFor="address_line_2"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
            >
              Address Line 2 (optional)
            </label>
          </div>
          <div className="relative mb-1 lg:mb-2">
            <input
              type="text"
              id="town"
              className={`peer appearance-none border-2 shadow ${formValidation.town ? "border-successColor ring-successColor" : formValidation.town === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
              name="town"
              value={formData.town || ""}
              onChange={handleTownChange}
              onBlur={validateTown}
              required
              placeholder="Town"
            />
            <label
              htmlFor="town"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
            >
              Town
            </label>
            <p
              className={`text-xs italic text-errorColor lg:text-sm ${formValidation.town !== false && "invisible"}`}
            >
              Please provide a valid town.
            </p>
          </div>
          <div className="relative mb-1 lg:mb-2">
            <input
              type="text"
              id="county"
              className={`peer appearance-none border-2 shadow ${formValidation.county ? "border-successColor ring-successColor" : formValidation.county === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
              name="county"
              value={formData.county || ""}
              onChange={handleCountyChange}
              onBlur={validateCounty}
              required
              placeholder="County"
            />
            <label
              htmlFor="county"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
            >
              County
            </label>
            <p
              className={`text-xs italic text-errorColor lg:text-sm ${formValidation.county !== false && "invisible"}`}
            >
              Please provide a valid county.
            </p>
          </div>
          <div className="relative mb-2">
            <input
              type="text"
              id="postcode"
              className={`peer appearance-none border-2 shadow ${formValidation.postcode ? "border-successColor ring-successColor" : formValidation.postcode === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
              name="postcode"
              value={formData.postcode}
              readOnly
              required
              placeholder="Postcode"
            />
            <label
              htmlFor="postcode"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
            >
              Postcode
            </label>
          </div>
          <div className="flex justify-center">
            <button
              className="rounded-md border border-transparent bg-secondaryColor px-2 py-1 text-center text-xs text-primaryColor shadow-sm transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white lg:px-4 lg:py-2 lg:text-lg"
              type="button"
              onClick={() => navigate("/quote/quote-selection")}
            >
              Cancel
            </button>
            <button
              className="ml-16 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-xs text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none lg:px-4 lg:py-2 lg:text-lg"
              type="submit"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerDetailsCollection;
