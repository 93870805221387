import React, { useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import { createCompany } from "../../api/company";
import { toast } from "react-toastify";
import { setDefaultCompanyUuid } from "../../lib/persistence";
import { useNavigate } from "react-router-dom";
import { refreshAccessToken } from "../../lib/auth";

const StringInput = ({
  id,
  name,
  message,
  value,
  setValue,
  isValid,
  onBlur,
}) => {
  const [touched, setTouched] = useState(false);

  return (
    <div className="relative mb-2">
      <input
        type="text"
        id={id}
        className={`peer h-10 w-full appearance-none rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none focus:ring`}
        name={id}
        required
        placeholder={name}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={(e) => {
          onBlur(e);
          setTouched(true);
        }}
      />
      <label className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-sm text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1">
        {name}
      </label>
      <p
        className={`${(isValid || !touched) && "invisible"} text-xs italic text-errorColor`}
      >
        {message}
      </p>
    </div>
  );
};

const CompanyTypeSelect = ({
  name,
  message,
  value,
  setValue,
  isValid,
  onBlur,
}) => {
  const handleCheckboxChange = (type) => {
    if (value.includes(type)) {
      setValue(value.filter((v) => v !== type));
    } else {
      setValue([...value, type]);
    }
  };
  const [touched, setTouched] = useState(false);

  return (
    <div className="mb-2">
      <div className="relative w-full border-2 shadow">
        <label className="p-2 text-sm font-medium text-gray-500">{name}</label>
        <div className="mt-2 flex space-x-4 p-2">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="size-4 text-blue-600"
              checked={value.includes("brokerage")}
              onChange={() => handleCheckboxChange("brokerage")}
              onBlur={(e) => {
                onBlur(e);
                setTouched(true);
              }}
            />
            <span className="ml-2 text-sm text-gray-700">Brokerage</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="size-4 text-blue-600"
              checked={value.includes("supplier")}
              onChange={() => handleCheckboxChange("supplier")}
              onBlur={onBlur}
            />
            <span className="ml-2 text-sm text-gray-700">Supplier</span>
          </label>
        </div>
      </div>
      <p
        className={`text-xs italic text-errorColor ${(isValid || !touched) && "invisible"}`}
      >
        {message}
      </p>
    </div>
  );
};

const CreateCompany = () => {
  const navigate = useNavigate();

  const [validation, setValidation] = useState({
    name: false,
    contact_email: false,
    types: false,
    contact_phone_number: false,
    address_line_1: false,
    address_line_2: false,
    town: false,
    county: false,
    postcode: false,
  });
  const [formData, setFormData] = useState({
    name: "",
    contact_email: "",
    types: [],
    contact_phone_number: "",
    address_line_1: "",
    address_line_2: "",
    town: "",
    county: "",
    postcode: "",
  });

  const handleFormSubmit = (e) => {
    const handle = async () => {
      try {
        const uuid = await createCompany(formData);
        setDefaultCompanyUuid(uuid);
        navigate("/");
        await refreshAccessToken();
        toast.success("Company created successfully");
      } catch (error) {
        console.error(error);
        toast.error("Failed to create company");
      }
    };
    e.preventDefault();
    handle();
  };

  return (
    <PageWrapper>
      <div className="m-auto my-6 w-9/12">
        <h1 className="mb-8 block text-2xl">Create a Company</h1>

        <div className="max-w-sm">
          <form onSubmit={handleFormSubmit}>
            <StringInput
              id="name"
              name="Name"
              message="Please enter a valid company name."
              value={formData.name}
              setValue={(value) => setFormData({ ...formData, name: value })}
              isValid={validation.name}
              onBlur={() =>
                setValidation({
                  ...validation,
                  name: formData.name.trim().length > 3,
                })
              }
            />
            <StringInput
              id="contact_email"
              name="Contact Email"
              message="Please enter a valid email address."
              value={formData.contact_email}
              setValue={(value) =>
                setFormData({ ...formData, contact_email: value })
              }
              isValid={validation.contact_email}
              onBlur={() =>
                setValidation({
                  ...validation,
                  contact_email:
                    formData.contact_email.trim().length > 3 &&
                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.contact_email),
                })
              }
            />
            <CompanyTypeSelect
              id="company_type"
              name="Company Type"
              message="Please select at least one company type."
              value={formData.types}
              setValue={(types) => setFormData({ ...formData, types })}
              isValid={validation.types}
              onBlur={() =>
                setValidation({
                  ...validation,
                  types: formData.types.length > 0,
                })
              }
            />
            <StringInput
              id="contact_phone_number"
              name="Contact Phone Number"
              message="Please enter a valid UK Phone Number."
              value={formData.contact_phone_number}
              setValue={(value) =>
                setFormData({ ...formData, contact_phone_number: value })
              }
              isValid={validation.contact_phone_number}
              onBlur={() =>
                setValidation({
                  ...validation,
                  contact_phone_number:
                    formData.contact_phone_number.trim().length > 3,
                })
              }
            />
            <StringInput
              id="address_line_1"
              name="Address Line 1"
              message="Please enter the Company's address line 1."
              value={formData.address_line_1}
              setValue={(value) =>
                setFormData({ ...formData, address_line_1: value })
              }
              isValid={validation.address_line_1}
              onBlur={() =>
                setValidation({
                  ...validation,
                  address_line_1: formData.address_line_1.trim().length > 3,
                })
              }
            />
            <StringInput
              id="address_line_2"
              name="Address Line 2"
              message="Please enter the Company's address line 2."
              value={formData.address_line_2}
              setValue={(value) =>
                setFormData({ ...formData, address_line_2: value })
              }
              isValid={validation.address_line_2}
              onBlur={() =>
                setValidation({
                  ...validation,
                  address_line_2: formData.address_line_2.trim().length > 3,
                })
              }
            />
            <StringInput
              id="town"
              name="Town"
              message="Please enter the Company's town."
              value={formData.town}
              setValue={(value) => setFormData({ ...formData, town: value })}
              isValid={validation.town}
              onBlur={() =>
                setValidation({
                  ...validation,
                  town: formData.town.trim().length > 3,
                })
              }
            />
            <StringInput
              id="county"
              name="County"
              message="Please enter the Company's county."
              value={formData.county}
              setValue={(value) => setFormData({ ...formData, county: value })}
              isValid={validation.county}
              onBlur={() =>
                setValidation({
                  ...validation,
                  county: formData.county.trim().length > 3,
                })
              }
            />
            <StringInput
              id="postcode"
              name="Postcode"
              message="Please enter a valid UK postcode."
              value={formData.postcode}
              setValue={(value) =>
                setFormData({ ...formData, postcode: value })
              }
              isValid={validation.postcode}
              onBlur={() =>
                setValidation({
                  ...validation,
                  postcode: formData.postcode.trim().length > 3,
                })
              }
            />
            <div className="flex justify-end">
              <button
                disabled={Object.values(validation).some((v) => !v)}
                className="h-12 rounded-md border border-transparent bg-primaryColor px-4 py-2 text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500 disabled:shadow-none"
                type="submit"
              >
                Create Company
              </button>
            </div>
          </form>
        </div>
      </div>
    </PageWrapper>
  );
};

export default CreateCompany;
