import React, { useState, useEffect, useRef } from "react";
import PageWrapper from "../../components/PageWrapper";
import {
  fetchPriceList,
  uploadPriceList,
  deletePriceListItem,
  getPriceListVisibility,
  setPriceListVisibility,
  getPriceListImports,
  getPublicPriceList,
  deleteAllCompanyPriceListItems,
} from "../../api/quote";
import ConfirmationModal from "../../components/ConfirmationModal";
import { currencyFormat, relativeTimeFormatter } from "../../lib/formatters";
import { MdModeEdit, MdDeleteForever } from "react-icons/md";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";
import EditPriceListItem from "../../components/EditPriceListItem";
import { useGlobalContext } from "../../GlobalContext";
import { toast } from "react-toastify";
import Modal from "../../components/Modal";
import ManageExternalPriceList from "../../components/ManageExternalPriceList";
import { getCompanyPublicDetails } from "../../api/company";
import PaginationControls from "../../components/PaginationControls";
import { FaDownload, FaPlus } from "react-icons/fa6";
import { downloadCSVTemplate } from "../../lib/csv";
import AddPriceListItem from "../../components/AddPriceListItem";

const IsPublicToggle = ({ checked, handleChange, disabled }) => {
  return (
    <div className="relative">
      <label className="inline-flex cursor-pointer items-center">
        <label className="mr-2 bg-white px-1 text-base">Public</label>
        <input
          type="checkbox"
          className="peer sr-only"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
        <div className="peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
      </label>
    </div>
  );
};

const PriceListDropdown = ({
  externalPriceLists,
  onChange,
  selectedCompany,
  supplierNames,
  value,
}) => {
  return (
    <form>
      <select
        value={value}
        onChange={onChange}
        id="countries"
        className="flex h-fit cursor-pointer gap-2 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-sm text-secondaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white"
      >
        <option key={selectedCompany} value={selectedCompany}>
          Default (self-managed)
        </option>
        {externalPriceLists?.map((priceList) => (
          <option key={priceList.supplier_uuid} value={priceList.supplier_uuid}>
            {supplierNames[priceList.supplier_uuid]}
          </option>
        ))}
      </select>
    </form>
  );
};

const PriceList = () => {
  const { state } = useGlobalContext();
  const [priceList, setPriceList] = useState(null);
  const [filteredPriceList, setFilteredPriceList] = useState(null);
  const [file, setFile] = useState(null);
  const [rows, setRows] = useState(0);
  const fileInputRef = useRef(null);
  const [editingPriceList, setIsEditingPriceList] = useState(null);
  const [isPublic, setIsPublic] = useState(false);
  const [isPublicWorking, setIsPublicWorking] = useState(false);
  const [selectedPriceList, setSelectedPriceList] = useState(
    state.selectedCompany,
  );
  const [isManagingExternalPriceList, setIsManagingExternalPriceList] =
    useState(false);
  const [importedPriceLists, setImportedPriceLists] = useState([]);
  const [supplierNames, setSupplierNames] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [addingPriceListItem, setAddingPriceListItem] = useState(false);
  const [deletingPriceList, setDeletingPriceList] = useState(false);

  const defaultPriceListSelected = selectedPriceList === state.selectedCompany;

  const handleCsvImport = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      const content = e.target.result;
      const rows = content.split("\n");
      const nonEmptyRows = rows.filter((row) => row.trim() !== "");
      const rowCount = nonEmptyRows.length - 1; // minus header
      setRows(rowCount);
      setFile(file);
      event.target.value = null;
    };

    reader.readAsText(file);
  };

  const handleConfirmCsvImport = () => {
    const uploadFile = async () => {
      try {
        await uploadPriceList(state.selectedCompany, file);
        toast.success("Price list uploaded successfully");
        await loadPriceList();
      } catch (error) {
        console.error(error);
        toast.error("Failed to upload price list");
      }
      setFile(null);
    };

    uploadFile();
  };

  const loadSupplierNames = async () => {
    try {
      if (importedPriceLists.length < 1) return;
      const details = await getCompanyPublicDetails(
        importedPriceLists.map((ipl) => ipl.supplier_uuid),
      );
      const map = {};
      details.forEach((detail) => {
        map[detail.uuid] = detail.name;
      });
      setSupplierNames(map);
    } catch (error) {
      console.error(error);
    }
  };

  const loadPriceList = async () => {
    setPriceList(null);
    try {
      const priceList = !defaultPriceListSelected
        ? await getPublicPriceList(selectedPriceList)
        : await fetchPriceList(state.selectedCompany);

      if (!defaultPriceListSelected) {
        const imports = await getPriceListImports(state.selectedCompany);

        const markupFactor =
          imports.find(
            (importedPriceList) =>
              importedPriceList.supplier_uuid === selectedPriceList,
          )?.markup_factor || 1;

        priceList.forEach((priceListEntry) => {
          priceListEntry.waste_weight_prices.forEach((wasteWeightPrice) => {
            wasteWeightPrice.bulk_prices.forEach((bulkPrice) => {
              bulkPrice.inflated_price_gbp = bulkPrice.price_gbp * markupFactor;
            });
          });
        });
      }

      setPriceList(priceList);
      setFilteredPriceList(priceList);
      setPageCount(Math.ceil(priceList?.length / 15));
      setPageNumber(1);
    } catch (error) {
      console.error(error);
      toast.error("Failed to load price list");
    }
  };

  const loadIsPublic = async () => {
    setIsPublicWorking(true);
    try {
      const isPublic =
        (await getPriceListVisibility(state.selectedCompany)) == "public";
      setIsPublic(isPublic);
    } catch (error) {
      console.error(error);
    }
    setIsPublicWorking(false);
  };

  const loadImportedPriceLists = async () => {
    try {
      const importedPriceLists = await getPriceListImports(
        state.selectedCompany,
      );
      setImportedPriceLists(importedPriceLists);
    } catch (error) {
      console.error(error);
      toast.error("Failed to load imported price lists");
    }
  };

  useEffect(() => {
    loadPriceList();
    loadIsPublic();
    loadImportedPriceLists();
  }, [state.selectedCompany, selectedPriceList]);

  useEffect(() => {
    setSelectedPriceList(state.selectedCompany);
  }, [state.selectedCompany]);

  useEffect(() => {
    loadSupplierNames();
  }, [importedPriceLists]);

  useEffect(() => {
    const prices = priceList?.filter((price) => {
      return (
        price.container_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        price.waste_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        price.postcode_area_district
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    });
    setFilteredPriceList(prices);
    setPageCount(Math.ceil(prices?.length / 15));
  }, [searchTerm]);

  const onSearchChange = (e) => {
    setPageNumber(1);
    setSearchTerm(e.target.value);
  };

  const formatPriceRange = (prices) => {
    if (prices?.length === 0) {
      return currencyFormat(0);
    }

    if (prices?.length === 1) {
      return currencyFormat(prices[0]);
    }
    prices.sort();
    return `${currencyFormat(prices[0])} - ${currencyFormat(prices[prices?.length - 1])}`;
  };

  const formatWeightRange = (weights) => {
    if (weights?.length === 0) {
      return "Not Provided";
    }

    if (weights?.length === 1) {
      return weights[0] + "Kg";
    }
    weights.sort();
    return `${weights[0]} - ${weights[weights?.length - 1]} Kg`;
  };

  const handleDeletingPriceList = async () => {
    try {
      await deleteAllCompanyPriceListItems(state.selectedCompany);
      setDeletingPriceList(false);
      toast.success("Price list deleted.");
      loadPriceList();
    } catch (error) {
      console.error(error);
      toast.error("Error deleting price list.");
    }
  };
  const th = "border-r-2 px-3 py-2";
  const td = "border-r-2 px-3 py-2 text-sm text-gray-900";
  const button =
    "flex h-fit cursor-pointer gap-2 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-sm text-secondaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white";

  return (
    <PageWrapper>
      <div className="size-full p-6 xl:p-8 2xl:p-10">
        <h1 className="mb-3 text-2xl font-bold text-black">Price List</h1>
        <div className="mb-4 flex flex-col gap-4 md:flex-row">
          <PriceListDropdown
            selectedCompany={state.selectedCompany}
            supplierNames={supplierNames}
            value={selectedPriceList}
            onChange={(e) => {
              setSelectedPriceList(e.target.value);
            }}
            externalPriceLists={importedPriceLists}
          />
          <button
            className={button}
            onClick={() => setIsManagingExternalPriceList(true)}
          >
            <p className="h-fit">Manage Imported Price Lists</p>
            <FaExternalLinkAlt className="m-1 text-green-500" />
          </button>
          {defaultPriceListSelected && (
            <button onClick={handleCsvImport} className={button}>
              <p className="h-fit">CSV Import</p>
              <FaFileImport className="m-1 text-green-500" />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept=".csv"
                onChange={handleFileChange}
              />
            </button>
          )}
          <button onClick={() => downloadCSVTemplate()} className={button}>
            <p className="h-fit">Download CSV Template</p>
            <FaDownload className="m-1 text-green-500" />
          </button>
          <button
            onClick={() => setAddingPriceListItem(true)}
            className={button}
          >
            <p className="h-fit">Add Item</p>
            <FaPlus className="m-1 text-green-500" />
          </button>
          <button onClick={() => setDeletingPriceList(true)} className={button}>
            <p className="h-fit">Delete All</p>
            <MdDeleteForever className="m-1 text-red-500" />
          </button>
        </div>
        {defaultPriceListSelected && (
          <IsPublicToggle
            checked={isPublic}
            disabled={isPublicWorking}
            handleChange={(e) => {
              setIsPublicWorking(true);
              const isChecked = e.target.checked;
              setPriceListVisibility(state.selectedCompany, isChecked)
                .then(() => {
                  setIsPublic(isChecked);
                  toast.success(
                    `Your company's price list is now ${isChecked ? "public" : "private"}`,
                  );
                })
                .finally(() => {
                  setIsPublicWorking(false);
                });
            }}
          />
        )}
        {priceList === null ? (
          <p className="my-6 text-gray-500">Loading...</p>
        ) : (
          <>
            <div className="mb-3 flex w-full items-center justify-between">
              <input
                name="search"
                id="search"
                type="text"
                placeholder="Search container, waste or postcode"
                onChange={onSearchChange}
                className="h-8 w-1/4 appearance-none rounded border-2 px-3 py-2 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring 2xl:h-10 2xl:text-base"
              />

              <PaginationControls
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                pageCount={pageCount}
              />
            </div>
            <table className="mb-3 w-full table-auto border-2 text-left text-sm text-gray-500">
              <thead className="bg-gray-100 text-xs uppercase text-gray-700">
                <tr>
                  <th className={th}>Container</th>
                  <th className={th}>Waste</th>
                  <th className={th}>Postcode Area District</th>
                  <th className={th}>
                    {defaultPriceListSelected ? <>Price</> : <>Base Price</>}
                  </th>
                  {!defaultPriceListSelected && (
                    <th className={th}>Inflated Price</th>
                  )}
                  <th className={th}>Weight</th>
                  <th className={th}>Updated By</th>
                  <th className={th}>Last Updated</th>
                  <th className={th} />
                  <th className={th} />
                </tr>
              </thead>
              <tbody>
                {filteredPriceList
                  ?.slice((pageNumber - 1) * 15, (pageNumber - 1) * 15 + 15)
                  .map((item, index) => (
                    <tr
                      key={item.uuid}
                      className={`${index % 2 !== 0 && "bg-gray-100"}`}
                    >
                      <td className={td}>{item.container_type}</td>
                      <td className={td}>{item.waste_type}</td>
                      <td className={td}>{item.postcode_area_district}</td>
                      <td className={td}>
                        {formatPriceRange(
                          item?.waste_weight_prices.flatMap((weightPrice) =>
                            weightPrice.bulk_prices.map(
                              (price) => price.price_gbp,
                            ),
                          ) || [],
                        )}
                      </td>
                      {!defaultPriceListSelected && (
                        <td className={td}>
                          {formatPriceRange(
                            item?.waste_weight_prices.flatMap((weightPrice) =>
                              weightPrice.bulk_prices.map(
                                (price) => price.inflated_price_gbp,
                              ),
                            ) || [],
                          )}
                        </td>
                      )}
                      <td className={td}>
                        {formatWeightRange(
                          item?.waste_weight_prices?.map(
                            (weight) => weight.weight_kilos,
                          ),
                        )}
                      </td>
                      <td className={td}>Jon McCormack</td>
                      <td className={td}>
                        {relativeTimeFormatter(item.updated_at)}
                      </td>
                      <td className={td}>
                        <MdModeEdit
                          className={`cursor-pointer text-lg ${!defaultPriceListSelected ? "cursor-not-allowed text-gray-400" : "text-blue-500"}`}
                          onClick={() => {
                            if (!defaultPriceListSelected) return;
                            setIsEditingPriceList(item);
                          }}
                        />
                      </td>
                      <td className={td}>
                        <MdDeleteForever
                          className={`cursor-pointer text-lg ${!defaultPriceListSelected ? "cursor-not-allowed text-gray-400" : "text-red-500"}`}
                          onClick={() => {
                            if (!defaultPriceListSelected) return;
                            deletePriceListItem(
                              state.selectedCompany,
                              item.uuid,
                            ).then(() => loadPriceList());
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <PaginationControls
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              pageCount={pageCount}
            />
          </>
        )}
      </div>
      <ConfirmationModal
        isOpen={file !== null}
        onClose={() => setFile(null)}
        onConfirm={handleConfirmCsvImport}
        question={`Are you sure you want to upload ${rows} rows?`}
      />
      <ConfirmationModal
        isOpen={deletingPriceList}
        onClose={() => setDeletingPriceList(false)}
        onConfirm={handleDeletingPriceList}
        question={`Are you sure you want to delete all entries? This action cannot be undone.`}
      />
      {isManagingExternalPriceList && (
        <Modal isOpen>
          <ManageExternalPriceList
            onClose={() => {
              setIsManagingExternalPriceList(false);
              loadImportedPriceLists();
            }}
          />
        </Modal>
      )}
      {editingPriceList !== null && (
        <Modal isOpen>
          <EditPriceListItem
            priceListItem={editingPriceList}
            closeModalCb={() => {
              loadPriceList();
              setIsEditingPriceList(null);
            }}
          />
        </Modal>
      )}
      {addingPriceListItem && (
        <Modal isOpen>
          <AddPriceListItem
            priceListItem={editingPriceList}
            closeModalCb={() => {
              loadPriceList();
              setAddingPriceListItem(false);
            }}
          />
        </Modal>
      )}
    </PageWrapper>
  );
};

export default PriceList;
