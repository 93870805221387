import csvFile from "../assets/price-list-template.csv";
export const downloadCSVTemplate = async () => {
  try {
    const a = document.createElement("a");
    a.href = csvFile;
    a.download = "price-list-template.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error("Error fetching the CSV file:", error);
  }
};
