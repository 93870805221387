import React from "react";

const PaginationControls = ({ pageNumber, setPageNumber, pageCount }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-2 xl:gap-4 2xl:gap-6">
      <p className="text-base xl:text-lg 2xl:text-xl">
        Showing page{" "}
        <span className="font-bold">{pageCount === 0 ? "0" : pageNumber}</span>{" "}
        of <span className="font-bold">{pageCount}</span>
      </p>
      <div className="flex gap-4 xl:gap-6 2xl:gap-8">
        {pageNumber !== 1 && (
          <button
            className="h-8 cursor-pointer rounded-md border border-transparent bg-secondaryColor px-2 py-1 text-center text-sm text-primaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white"
            type="button"
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            Previous
          </button>
        )}
        {pageNumber !== pageCount && pageCount !== 0 && (
          <button
            type="button"
            onClick={() => setPageNumber(pageNumber + 1)}
            className="h-8 cursor-pointer rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-sm text-secondaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};
export default PaginationControls;
