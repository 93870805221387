import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { currencyFormat } from "./formatters";
import {
  frequencyLookup,
  wasteTypeNameLookup,
  weeklyFrequencyLookup,
} from "./lookups";

export const generateQuotePDFBytes = async (name, quote) => {
  const container = document.createElement("div");
  container.style.width = "800px";
  container.className = "p-4";

  container.innerHTML = `
  <h2 style="margin-bottom: 8px; margin-top: 20px; font-size: 1.25rem; font-weight: bold; color: black;">
    Quote Details
  </h2>
  <p style="margin-bottom: 8px; font-size: 1rem; color: black;">
    <strong>Supplier: </strong>${quote?.supplier_name || quote?.supplier_uuid}
  </p>
  <p style="border-bottom: 2px solid #718096; padding-bottom: 20px; font-size: 1rem; color: black;">
    <strong>Total Monthly Price: </strong>${currencyFormat(quote?.price_gbp || quote?.total_price_gbp)}
  </p>
  <h2 style="margin-bottom: 8px; margin-top: 20px; font-size: 1.25rem; font-weight: bold; color: black;">
    Service Breakdown
  </h2>
  <div style="width: 100%; overflow-x: auto;">
    <table style="width: 100%; border-collapse: collapse; text-align: left; font-size: 0.875rem; color: #4A5568;">
      <thead style="background-color: #F7FAFC; font-size: 0.75rem; text-transform: uppercase; color: #4A5568;">
        <tr>
          <th style="padding: 8px; border: 1px solid #E2E8F0;">Type</th>
          <th style="padding: 8px; border: 1px solid #E2E8F0;">Container</th>
          <th style="padding: 8px; border: 1px solid #E2E8F0;">Price</th>
          <th style="padding: 8px; border: 1px solid #E2E8F0;">Quantity</th>
          <th style="padding: 8px; border: 1px solid #E2E8F0;">Frequency</th>
          <th style="padding: 8px; border: 1px solid #E2E8F0;">Monthly Price</th>
          <th style="padding: 8px; border: 1px solid #E2E8F0;">Excess Weight Charge</th>
          <th style="padding: 8px; border: 1px solid #E2E8F0;">Weight Restriction</th>
        </tr>
      </thead>
      <tbody>
        ${
          quote?.pricing_breakdown &&
          quote?.pricing_breakdown
            ?.map(
              (breakdown) =>
                `<tr style="border-bottom: 1px solid #E2E8F0; background-color: white;">
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${wasteTypeNameLookup[breakdown.type]}
                </td>
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${breakdown.container}
                </td>
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${currencyFormat(breakdown.price_gbp)}
                </td>
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${breakdown.quantity}
                </td>
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${
                    +breakdown.frequency >= 4.33
                      ? weeklyFrequencyLookup[breakdown.frequency]
                      : frequencyLookup[breakdown.frequency]
                  }
                </td>
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${currencyFormat(breakdown.total_price_gbp)}
                </td>
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${
                    breakdown.excess_weight_charge_gbp
                      ? currencyFormat(breakdown.excess_weight_charge_gbp)
                      : "None"
                  }
                </td>
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${breakdown.weight_restriction_kilos ?? "None"}
                </td>
              </tr>`,
            )
            .join("")
        }
         ${
           quote?.price_breakdown &&
           quote?.price_breakdown
             ?.map(
               (breakdown) =>
                 `<tr style="border-bottom: 1px solid #E2E8F0; background-color: white;">
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${wasteTypeNameLookup[breakdown.type]}
                </td>
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${breakdown.container}
                </td>
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${currencyFormat(breakdown.price_gbp)}
                </td>
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${breakdown.quantity}
                </td>
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${
                    +breakdown.frequency >= 4.33
                      ? weeklyFrequencyLookup[breakdown.frequency]
                      : frequencyLookup[breakdown.frequency]
                  }
                </td>
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${currencyFormat(breakdown.total_price_gbp)}
                </td>
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${
                    breakdown.excess_weight_charge_gbp
                      ? currencyFormat(breakdown.excess_weight_charge_gbp)
                      : "None"
                  }
                </td>
                <td style="padding: 8px; font-size: 0.875rem; color: #1A202C; border: 1px solid #E2E8F0;">
                  ${breakdown.weight_restriction_kilos ?? "None"}
                </td>
              </tr>`,
             )
             .join("")
         }
      </tbody>
    </table>
  </div>
`;

  document.body.appendChild(container);

  const canvas = await html2canvas(container);

  document.body.removeChild(container);

  const imgData = canvas.toDataURL("image/png");
  const pdf = new jsPDF();
  const imgProps = pdf.getImageProperties(imgData);
  const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
  const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  pdf.setFontSize(18);
  pdf.text(`Hi ${name}, here is your quote:`, 10, 20);
  pdf.addImage(imgData, "PNG", 10, 30, pdfWidth, pdfHeight);
  return pdf.output("blob");
};

export const downloadPDF = (pdfBytes, filename = "quote.pdf") => {
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
