import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import { Outlet, useNavigate } from "react-router-dom";

const QuoteInternal = () => {
  const [desiredServices, setDesiredServices] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [quoteSessionUUID, setQuoteSessionUUID] = useState(null);
  const [customerPostcode, setCustomerPostcode] = useState(null);
  const [desiredQuote, setDesiredQuote] = useState(null);
  const [quoteAcceptConfirm, setQuoteAcceptConfirm] = useState(false);
  const pages = [
    { page: "postcode", title: "Postcode" },
    { page: "waste-services", title: "Services" },
    { page: "quote-selection", title: "Quotes" },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    navigate("/internal-quote/postcode");
  }, []);

  return (
    <PageWrapper>
      <div className="flex size-full flex-col">
        <Outlet
          context={[
            customerPostcode,
            setCustomerPostcode,
            desiredServices,
            setDesiredServices,
            setQuotes,
            setQuoteSessionUUID,
            quotes,
            setDesiredQuote,
            setQuoteAcceptConfirm,
            desiredQuote,
            quoteAcceptConfirm,
            quoteSessionUUID,
            pages,
          ]}
        />
      </div>
    </PageWrapper>
  );
};

export default QuoteInternal;
