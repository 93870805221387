import React, { useState } from "react";
import { useGlobalContext } from "../../GlobalContext";
import { addCompanyUser } from "../../api/company";
import ErrorMessage from "../ErrorMessage";

const AddCompanyUser = ({ closeModalCb }) => {
  const [error, setError] = useState("");
  const { state } = useGlobalContext();
  const [formData, setFormData] = useState({
    email: "",
    company_role: "reader",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };

      return updatedData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const create = async () => {
      try {
        await addCompanyUser(
          state.selectedCompany,
          formData.email,
          formData.company_role,
        );
        closeModalCb();
      } catch (error) {
        setError(error.message);
      }
    };

    create();
  };

  return (
    <div className="flex min-h-[40vh] min-w-[40vw] flex-col items-center justify-center gap-4 xl:gap-6 2xl:gap-8">
      <h2 className="text-base font-bold xl:text-lg 2xl:text-xl">
        Add Company User
      </h2>
      {error && (
        <ErrorMessage>
          <p>{error}</p>
        </ErrorMessage>
      )}
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center justify-center"
      >
        <div className="mb-4 w-5/6 xl:mb-6">
          <label
            htmlFor="email"
            className="mb-1 text-base text-gray-800 xl:mb-2 xl:text-xl 2xl:mb-3"
          >
            Email Address
          </label>
          <input
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="h-8 w-full appearance-none rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring xl:h-12 xl:px-3 xl:py-2 xl:text-lg"
          />
        </div>
        <div className="relative mb-4 w-5/6 xl:mb-6">
          <label
            htmlFor="company_role"
            className="mb-1 text-base text-gray-800 xl:mb-2 xl:text-xl 2xl:mb-3"
          >
            Company Role
          </label>
          <select
            type="text"
            id="company_role"
            name="company_role"
            value={formData.company_role}
            onChange={handleChange}
            required
            className="h-8 w-full appearance-none rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring xl:h-12 xl:px-3 xl:py-2 xl:text-lg"
          >
            <option value="admin">Admin</option>
            <option value="writer">Writer</option>
            <option value="reader">Reader</option>
          </select>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.2"
            stroke="currentColor"
            className="absolute right-2.5 top-8 ml-1 size-5 text-gray-700 xl:top-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
            />
          </svg>
        </div>
        <div className="flex w-full justify-center gap-8">
          <button
            type="button"
            onClick={closeModalCb}
            className="h-8 rounded-md border border-transparent bg-secondaryColor px-2 py-1 text-center text-sm text-primaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white xl:h-12 xl:px-4 xl:py-2 xl:text-xl"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="h-8 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-sm text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none xl:h-12 xl:px-4 xl:py-2 xl:text-xl"
          >
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

AddCompanyUser.propTypes = {};

export default AddCompanyUser;
