import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { createWhitelabelQuote } from "../../api/quote";
import ProgressBar from "../ProgressBar";
import { toast } from "react-toastify";
import { getCompanyPublicDetails } from "../../api/company";

const EmailCollection = () => {
  const context = useOutletContext();
  const navigate = useNavigate();
  const setCustomerDetails = context[0];
  const customerDetails = context[1];
  const desiredServices = context[2];
  const setQuotes = context[4];
  const setQuoteSessionUUID = context[5];
  const pages = context[12];
  const [customerEmail, setCustomerEmail] = useState(
    customerDetails?.email || "",
  );
  const [formValidation, setFormValidation] = useState(null);

  useEffect(() => {
    if (customerDetails?.postcode === "") {
      navigate("/quote/postcode");
      return;
    } else if (desiredServices.length === 0) {
      navigate("/quote/waste-services");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValidEmail = validateEmail();

    if (!isValidEmail) {
      return;
    }
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      email: customerEmail,
    }));
    try {
      const newQuoteSession = await createWhitelabelQuote(
        customerEmail,
        customerDetails?.postcode,
        desiredServices,
      );
      if (newQuoteSession?.quotes?.length > 0) {
        // const nonRedactedSuppliers = [];
        // newQuoteSession?.quotes?.forEach((quote) => {
        //   if (!quote.redact_supplier_details) {
        //     nonRedactedSuppliers.push(quote.supplier_uuid);
        //   }
        // });
        // const companyDetails =
        //   await getCompanyPublicDetails(nonRedactedSuppliers);
        // const companyLookup = {};
        // companyDetails?.forEach((company) => {
        //   companyLookup[company.uuid] = company.name;
        // });
        // const quotesWithSupplierName = newQuoteSession?.quotes?.map(
        //   (quote, index) => {
        //     const newQuote = { ...quote };
        //     if (quote.redact_supplier_details) {
        //       newQuote.supplier_name = `Supplier ${index + 1}`;
        //     } else {
        //       newQuote.supplier_name = companyLookup[quote.supplier_uuid];
        //     }
        //     return newQuote;
        //   },
        // );

        setQuotes(newQuoteSession.quotes);
      } else {
        setQuotes([]);
      }
      setQuoteSessionUUID(newQuoteSession.quote_session_uuid);
      navigate("/quote/quote-selection");
    } catch (error) {
      toast.error("Failed to create quote");
      console.error(error);
    }
  };

  const validateEmail = () => {
    const emailRegex =
      // TODO(SW-25)
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    if (emailRegex.test(customerEmail)) {
      setFormValidation(true);
      return true;
    } else {
      setFormValidation(false);
      return false;
    }
  };

  const handleEmailChange = (e) => {
    const emailRegex =
      // TODO(SW-25)
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    formValidation === false &&
      emailRegex.test(e.target.value) &&
      setFormValidation(true);
    setCustomerEmail(e.target.value);
  };

  return (
    <>
      <ProgressBar
        setPage={(page) => navigate(`/quote/${page}`)}
        currPageIndex={2}
        pages={pages}
      />
      <div className="flex w-full flex-1 flex-col items-center justify-center px-4 pb-16 pt-12 xl:pb-20 xl:pt-16">
        <h1 className="mb-2 block text-center text-lg font-bold text-black md:text-xl lg:text-2xl xl:mb-4 xl:text-3xl">
          Please enter your email.
        </h1>
        <h2 className="mb-4 block text-center text-base text-black md:text-lg lg:mb-10 lg:text-xl xl:mb-20 xl:text-2xl">
          We'll send over your quotes so you can access them later on.
        </h2>
        <form
          onSubmit={handleSubmit}
          noValidate
          className="flex w-3/4 flex-col items-center lg:w-2/3"
        >
          <div className="relative w-3/4 md:mb-4 lg:mb-8 xl:mb-16">
            <input
              type="text"
              id="email"
              className={`peer appearance-none border-2 shadow ${formValidation ? "border-successColor ring-successColor" : formValidation === false && "border-errorColor ring-errorColor"} h-10 w-full rounded px-2 py-1 text-base leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring md:h-12 lg:h-16 lg:px-3 lg:py-2 lg:text-lg`}
              name="email"
              value={customerEmail || ""}
              onChange={handleEmailChange}
              onBlur={validateEmail}
              required
              placeholder="Email"
            />
            <label
              htmlFor="email"
              className="absolute left-0 top-2 ml-2 -translate-y-5 bg-white px-1 text-base text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-5 peer-focus:px-1 md:top-2.5 md:-translate-y-6 md:peer-focus:-translate-y-6 lg:top-4 lg:-translate-y-8 lg:text-xl lg:peer-focus:-translate-y-8"
            >
              Email
            </label>
            <p
              className={`my-1 text-xs italic text-errorColor md:text-sm lg:text-base ${formValidation !== false && "invisible"}`}
            >
              Please provide a valid email.
            </p>
          </div>
          <button
            className="rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-base text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none lg:h-14 lg:px-8 lg:text-lg xl:text-2xl"
            type="submit"
          >
            Continue to quotes
          </button>
        </form>
      </div>
    </>
  );
};

export default EmailCollection;
