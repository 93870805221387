import React, { useEffect, useState } from "react";
import WhitelabelWrapper from "../../components/WhitelabelWrapper";
import { Outlet, useNavigate } from "react-router-dom";

const QuoteWhitelabel = ({ whitelabelIconLink }) => {
  const [desiredServices, setDesiredServices] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [quoteSessionUUID, setQuoteSessionUUID] = useState(null);
  const [customerDetails, setCustomerDetails] = useState({
    name: "",
    company_name: "",
    email: "",
    phone_number: "",
    address_line_1: "",
    address_line_2: "",
    town: "",
    postcode: "",
    county: "",
  });
  const [desiredQuote, setDesiredQuote] = useState(null);
  const [quoteAcceptConfirm, setQuoteAcceptConfirm] = useState(false);
  const [PDFBytes, setPDFBytes] = useState(null);
  const pages = [
    { page: "postcode", title: "Postcode" },
    { page: "waste-services", title: "Services" },
    { page: "email", title: "Email" },
    { page: "quote-selection", title: "Quotes" },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    navigate("/quote/postcode");
  }, []);

  return (
    <WhitelabelWrapper iconLink={whitelabelIconLink}>
      <div className="flex size-full flex-col">
        <Outlet
          context={[
            setCustomerDetails,
            customerDetails,
            desiredServices,
            setDesiredServices,
            setQuotes,
            setQuoteSessionUUID,
            quotes,
            setDesiredQuote,
            setQuoteAcceptConfirm,
            desiredQuote,
            quoteAcceptConfirm,
            quoteSessionUUID,
            pages,
            PDFBytes,
            setPDFBytes,
          ]}
        />
      </div>
    </WhitelabelWrapper>
  );
};

export default QuoteWhitelabel;
