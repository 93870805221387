import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Error from "./pages/Error";
import Company from "./pages/Company";
import OAuthCallback from "./pages/OAuthCallback";
import CompanyUsers from "./pages/CompanyUsers";
import PriceList from "./pages/PriceList";
import WasteContainerInfo from "./pages/WasteContainerInfo";
import Whitelabel from "./pages/Whitelabel";
import { fetchRefererWhitelabelDetails } from "./api/quote";
import { ToastContainer } from "react-toastify";
import SystemUsers from "./pages/SystemUsers";
import CompanyManagement from "./pages/CompanyManagement";
import CreateCompany from "./pages/CreateCompany";
import EmailCollection from "./components/EmailCollection";
import CustomerDetailsCollection from "./components/CustomerDetailsCollection";
import QuoteAccepted from "./components/QuoteAccepted";
import QuoteManagement from "./pages/QuoteManagement";
import QuoteInternal from "./pages/QuoteInternal";
import PostcodeCollectionInternal from "./components/PostcodeCollectionInternal";
import WasteServiceSelectionInternal from "./components/WasteServiceSelectionInternal";
import QuoteSelectionInternal from "./components/QuoteSelectionInternal";
import QuoteDetails from "./pages/QuoteManagement/QuoteDetails";
import QuoteWhitelabel from "./pages/QuoteWhitelabel";
import PostcodeCollectionWhitelabel from "./components/PostcodeCollectionWhitelabel";
import WasteServiceSelectionWhitelabel from "./components/WasteServiceSelectionWhitelabel";
import QuoteSelectionWhitelabel from "./components/QuoteSelectionWhitelabel";

// TODO(Jon): on app mount, if we have a refresh token, set a timeout to refresh the access token
// on access token expiry

export const App = () => {
  const [isWhitelabel, setIsWhitelabel] = useState(false);
  const [whitelabelError, setWhitelabelError] = useState(false);
  const [whitelabelIconLink, setWhitelabelIconLink] = useState("");

  useEffect(() => {
    const fetchWhitelabelDetails = async () => {
      try {
        const details = await fetchRefererWhitelabelDetails();
        if (details) {
          document.documentElement.style.setProperty(
            "--primary-color",
            details.primary_color,
          );
          document.documentElement.style.setProperty(
            "--secondary-color",
            details.secondary_color,
          );
          document.documentElement.style.setProperty(
            "--success-color",
            details.success_color,
          );
          document.documentElement.style.setProperty(
            "--error-color",
            details.error_color,
          );
          document.documentElement.style.setProperty(
            "--background-color",
            details.background_color,
          );
          document.documentElement.style.setProperty(
            "--font-family",
            details.font_family,
          );
          setWhitelabelIconLink(details.icon_link);
          setIsWhitelabel(true);
        }
      } catch (error) {
        console.error(error);
        setWhitelabelError(true);
      }
    };

    fetchWhitelabelDetails();
  }, []);

  return (
    <div>
      <Router>
        <Routes>
          {whitelabelError ? (
            <Route path="*" element={<Error />} />
          ) : isWhitelabel ? (
            <Route
              path="*"
              element={
                <QuoteWhitelabel whitelabelIconLink={whitelabelIconLink} />
              }
            >
              <Route
                path="quote/postcode"
                element={<PostcodeCollectionWhitelabel />}
              />
              <Route
                path="quote/waste-services"
                element={<WasteServiceSelectionWhitelabel />}
              />
              <Route path="quote/email" element={<EmailCollection />} />
              <Route
                path="quote/quote-selection"
                element={<QuoteSelectionWhitelabel />}
              />
              <Route
                path="quote/customer-details"
                element={<CustomerDetailsCollection />}
              />
              <Route path="quote/accepted" element={<QuoteAccepted />} />
            </Route>
          ) : (
            <>
              <Route path="/company" element={<Company />} />
              <Route path="/company-users" element={<CompanyUsers />} />
              <Route path="/company-quotes" element={<QuoteManagement />} />
              <Route
                path="/company-quotes/quote/:quoteuuid"
                element={<QuoteDetails />}
              />
              <Route path="/system-users" element={<SystemUsers />} />
              <Route path="/internal-quote" element={<QuoteInternal />}>
                <Route
                  path="postcode"
                  element={<PostcodeCollectionInternal />}
                />
                <Route
                  path="waste-services"
                  element={<WasteServiceSelectionInternal />}
                />
                <Route
                  path="quote-selection"
                  element={<QuoteSelectionInternal />}
                />
              </Route>
              <Route path="/price-list" element={<PriceList />} />
              <Route
                path="/waste-container-info"
                element={<WasteContainerInfo />}
              />
              <Route path="/oauth-callback" element={<OAuthCallback />} />
              <Route path="/whitelabel" element={<Whitelabel />} />
              <Route path="/" element={<Company />} />
              <Route path="/company" element={<Company />} />
              <Route
                path="/company-management"
                element={<CompanyManagement />}
              />
              <Route path="/create-company" element={<CreateCompany />} />
              <Route path="*" element={<Error />} />
            </>
          )}
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
};

export default App;
