import React from "react";
import { useNavigate } from "react-router-dom";
import PageWrapper from "../../components/PageWrapper";

const Error = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <PageWrapper>
      <div className="p-8 text-center">
        <h1 className="mb-4 text-2xl font-bold text-red-600">
          Something Went Wrong
        </h1>
        <p className="mb-6 text-gray-700">
          We're sorry, but something went wrong. Please try again later.
        </p>
        <button
          onClick={handleGoBack}
          className="rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Go Back
        </button>
      </div>
    </PageWrapper>
  );
};

export default Error;
