import React from "react";

const ProgressBar = ({ setPage, currPageIndex, pages }) => {
  return (
    <section className="my-2 flex w-full flex-col items-center lg:my-4 xl:my-6">
      <div>
        <p className="mb-1 block text-lg font-bold text-black md:text-xl lg:mb-3 lg:text-2xl xl:mb-5 xl:text-3xl">
          Step {currPageIndex + 1} of {pages.length}
        </p>
        <div className="flex items-center">
          {pages.map((page, index) => {
            return (
              <div key={index} className="flex items-center">
                <button
                  onClick={() => setPage(page.page)}
                  disabled={currPageIndex < index}
                  className={`z-10 -mx-1 size-8 rounded-full md:size-10 lg:size-12 xl:size-16 ${currPageIndex >= index ? "bg-primaryColor" : "bg-gray-300"}`}
                >
                  <p
                    className={`text-[0.4rem] md:text-[0.5rem] lg:text-[0.6rem] xl:text-xs ${currPageIndex >= index ? "text-white" : "text-black"}`}
                  >
                    {page.title}
                  </p>
                </button>
                {index !== pages.length - 1 && (
                  <div
                    className={`h-2 w-16 md:w-24 lg:w-32 xl:h-3 xl:w-40 2xl:h-3 ${currPageIndex > index ? "bg-primaryColor" : "bg-gray-300"}`}
                  ></div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ProgressBar;
