import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { patchWasteContainerInfo } from "../../api/quote";
import { useGlobalContext } from "../../GlobalContext";
import { toast } from "react-toastify";

const EditWasteContainerInfo = ({ wasteContainerInfo, closeModalCb }) => {
  const { state } = useGlobalContext();
  const [formData, setFormData] = useState({
    container_type: "",
    waste_type: "",
    excess_weight_charge_gbp: 0,
  });

  useEffect(() => {
    if (wasteContainerInfo) {
      setFormData({
        excess_weight_charge_gbp: wasteContainerInfo.excess_weight_charge_gbp,
      });
    }
  }, [wasteContainerInfo]);

  const handleEdit = async () => {
    // this is only a partial update, so provide only what we need
    if (
      formData.excess_weight_charge_gbp ===
      wasteContainerInfo.excess_weight_charge_gbp
    ) {
      return;
    }

    await patchWasteContainerInfo(state.selectedCompany, {
      container_type: wasteContainerInfo.container_type,
      waste_type: wasteContainerInfo.waste_type,
      ...formData,
    });
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "number" ? parseFloat(value) : value,
      };

      return updatedData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await handleEdit();
      closeModalCb();
      toast.success("Update successful");
    } catch (error) {
      console.error(error);
      if (error.status === 400) {
        toast.error((await error.json()).error);
        return;
      }
      toast.error("Update failed");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-8 xl:p-10 2xl:p-12">
      <h2 className="mb-4 block text-center text-base font-bold text-black xl:mb-6 xl:text-lg">
        Edit Waste Container Info
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-1 w-full">
          <label
            htmlFor="container_type"
            className="mb-1 block text-base text-gray-800"
          >
            Container Type
          </label>
          <div className="h-8 w-full appearance-none rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow">
            {wasteContainerInfo?.container_type}
          </div>
        </div>
        <div className="mb-1 w-full">
          <label
            htmlFor="waste_type"
            className="mb-1 block text-base text-gray-800"
          >
            Waste Type
          </label>
          <div className="h-8 w-full appearance-none rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow">
            {wasteContainerInfo?.waste_type}
          </div>
        </div>
        <div className="mb-1 w-full">
          <label
            htmlFor="excess_weight_charge_gbp"
            className="mb-1 block text-base text-gray-800"
          >
            Excess Weight Charge (GBP)
          </label>
          <input
            type="number"
            id="excess_weight_charge_gbp"
            name="excess_weight_charge_gbp"
            className="h-8 w-full appearance-none rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring"
            value={formData.excess_weight_charge_gbp}
            onChange={handleChange}
            step="0.01"
            required
          />
        </div>
        <div className="mt-6 flex w-full justify-center">
          <button
            type="button"
            onClick={closeModalCb}
            className="h-8 rounded-md border border-transparent bg-secondaryColor px-2 py-1 text-center text-sm text-primaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-12 h-8 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-sm text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

EditWasteContainerInfo.propTypes = {
  wasteContainerInfo: PropTypes.object,
  closeModalCb: PropTypes.func.isRequired,
};

export default EditWasteContainerInfo;
